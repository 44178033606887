import * as React from 'react';
import placeholderImage from '../../../../../../../../assets/img/image-placeholder.png';
import Image from '../../../../../../../../models/image/Image';
import Masonry from 'react-masonry-css';
import './style/main-media-masonry.scss';

type ImageCellsProps = {
	images: Image[];
	onImageSelect: (image: Image) => any;
};
export const ImageCells: React.FunctionComponent<ImageCellsProps> = ({ images, onImageSelect }) => {
	return (
		<Masonry
			breakpointCols={{
				default: 3,
				1380: 3,
				1300: 2,
				990: 4,
				560: 3,
				520: 2,
			}}
			className='main-media-masonry-grid'
			columnClassName='main-media-masonry-grid_column'
		>
			{images.map((image: Image, index: number) => {
				if (image && image.urls) {
					return (
						<img
							src={image.urls && image.urls.uploaded && image.urls.uploaded.embed ? image.urls.uploaded.embed : placeholderImage}
							key={`image-preview-key-${index}`}
							className='img-fluid  w-100 mb-1 cursor-pointer'
							onClick={() => onImageSelect(image)}
							alt={image.description}
						/>
					);
				}
			})}
		</Masonry>
	);
};
