import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { assetsPlaceholder } from '../../../../../../../../../constants/assetsPlaceholder';
import { imagePlaceHolder } from '../../../../../../../../../constants/resource-constants';
import { generateRefreshTimeOptionViewText } from '../../../../../partials/refresh-time-input/helper';
import OddsModelV2 from '../../../models/odds-widget.model';

type Properties = {
	preview: OddsModelV2;
	t: any;
	displayOddsLabel?: boolean;
};

export const OddsV2ViewComponent: React.FunctionComponent<Properties> = ({ preview, t, displayOddsLabel }) => {
	if (preview && preview.match && preview.match.id) {
		return (
			<div>
				{displayOddsLabel && (
					<Row className='text-left pt-2'>
						<Col>
							<h6>{t('odds_widget_for_match')}</h6>
						</Col>
					</Row>
				)}
				<Row className={`${displayOddsLabel ? 'pl-4 pr-4' : 'pt-2 pl-4 pr-4'}`}>
					<Col className='text-left'>
						<Row className='mb-1'>
							<Col>
								<div className='mb-1 font-weight-bold d-inline-block mr-5'>
									{preview.match.kickoffTime ? moment(preview.match.kickoffTime).format('MMM Do YYYY, HH:mm') : ''}
								</div>
								<div className='d-inline-block'>
									<strong className='p-1'>{preview.match.homeTeam ? preview.match.homeTeam.name : ''}</strong>
									<img
										height='40'
										alt={preview.match.homeTeam ? preview.match.homeTeam.name : ''}
										src={
											preview.match.homeTeam.assets && preview.match.homeTeam.assets.logo
												? preview.match.homeTeam.assets.logo.url
												: assetsPlaceholder.team
										}
										className='h-40 mx-1'
									/>
								</div>
								<div className='d-inline-block font-weight-bold'>:</div>
								<div className='d-inline-block'>
									<img
										height='40'
										alt={preview.match.awayTeam ? preview.match.awayTeam.name : ''}
										src={
											preview.match.awayTeam.assets && preview.match.awayTeam.assets.logo
												? preview.match.awayTeam.assets.logo.url
												: assetsPlaceholder.team
										}
										className='h-40 mx-1'
									/>
									<strong className='p-1'>{preview.match.awayTeam ? preview.match.awayTeam.name : ''}</strong>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<hr />
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{t('show')}:
								{preview.mainBookmaker && preview.mainBookmaker[0] && preview.mainBookmaker[0].id && (
									<img
										key={preview.mainBookmaker[0].id}
										height='20'
										alt={preview.mainBookmaker[0].name}
										className='h-20 mx-1'
										src={preview.mainBookmaker[0].assets[0] ? preview.mainBookmaker[0].assets[0].logo : imagePlaceHolder}
										title={preview.mainBookmaker[0].name}
									/>
								)}
							</Col>
						</Row>
						{preview.market && preview.market.name && preview.market.name.length > 0 && (
							<Row>
								<Col className='text-left'>
									<span>{t('default_market')}: </span>
									<strong>{t(preview.market.name)}</strong>
								</Col>
							</Row>
						)}
						{preview.marketValueType && preview.marketValueType.name && preview.marketValueType.name.length > 0 && (
							<Row>
								<Col className='text-left'>
									<span>{t('market_value_type')}: </span>
									<strong>{t(preview.marketValueType.name)}</strong>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</div>
		);
	}

	return null;
};
