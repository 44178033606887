import { compose } from 'redux';
import React, { useEffect, useRef, useState } from 'react';
import { SwatchesPicker } from 'react-color';
import HttpService from '../../../../../../services/rest/HttpService';
import {
	checkIfAwayShirtColorExists,
	checkIfColorsAvailable,
	checkIfMatchContainsColors,
	extractMatchColors,
	extractTeamIDsFromMatch,
	listenForOutsideClicks,
} from '../../../blocks/widgets/match/helpers/match-widget.helper';
import { customColors } from './color.constants';
import MatchModelV2 from '../../widgets-v2/football-single-event/models/MatchModelV2.model';

type Properties = {
	color: string;
	handleChangeColor: (color: any) => any;
	toggleTeamColorPicker: (toggle: boolean) => void;
	updateHomeTeamColor?: (color: string) => any;
	updateAwayTeamColor?: (color: string) => any;
	match: MatchModelV2;
	setMatchColor: (homeColor: string, awayTeamColor: string) => void;
	toggle: boolean;
	handleColorsRequest: (request: boolean) => void;
	requestMatchColors: boolean;
	blockId: string;
};

const TeamColorSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const menuRef = useRef(null);
	const [listening, setListening] = useState<boolean>(false);

	useEffect(() => {
		listenForOutsideClicks(listening, setListening, menuRef, props.toggleTeamColorPicker, props.toggle, props.blockId);
	});

	useEffect(() => {
		props.requestMatchColors && getTeamColors(props.match);
	}, [props.match]);

	const getTeamColors = (match: MatchModelV2) => {
		if (match && match.id) {
			HttpService.getMatchColors(match.id).then((matchData: object) => {
				let colors = extractMatchColors(matchData);
				if (checkIfColorsAvailable(colors.homeTeamColor, colors.awayTeamColor)) {
					//if both teams have colors and they are the same color
					let teamIDs = extractTeamIDsFromMatch(matchData);
					getAwayTeamsColor(teamIDs.awayTeam).then((result: any) => {
						props.setMatchColor(colors.homeTeamColor, result);
					});
					props.handleColorsRequest(false);
				} else if (checkIfMatchContainsColors(matchData)) {
					//check if at least one of the matches has a color
					props.setMatchColor(colors.homeTeamColor, colors.awayTeamColor);
					props.handleColorsRequest(false);
				} else {
					//none of the matches contains a color
					props.setMatchColor('', '');
					props.handleColorsRequest(false);
				}
			});
		}
	};

	const getAwayTeamsColor = (id: string) => {
		return HttpService.getTeamColors(id).then((response: any) => {
			return checkIfAwayShirtColorExists(response) ? response.data.shirt_colors[1].color_code : null;
		});
	};

	const { color, handleChangeColor } = props;

	return (
		<div ref={menuRef}>
			<SwatchesPicker color={color} colors={customColors} width={289} onChange={handleChangeColor} />
		</div>
	);
};

export default compose()(TeamColorSelectComponent);
