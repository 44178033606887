import React from 'react';
import { Col, Row } from 'reactstrap';
import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';
import moment from 'moment';
import FootballLineupsModel from '../models/football-lineups.model';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';
import { useTranslation } from 'react-i18next';
import { checkIfElementSelected } from '../../helpers/block-v2-config.helper';

type Properties = {
	preview: FootballLineupsModel;
};

export const FootballLineupsViewComponent: React.FunctionComponent<Properties> = ({ preview }) => {
	const { t } = useTranslation();

	if (preview.match && preview.match.id) {
		const homeTeamColor = preview.homeTeamColor;
		const awayTeamColor = preview.awayTeamColor;
		const homeTeamName = preview.match.homeTeam.name ? preview.match.homeTeam.name : '';
		const awayTeamName = preview.match.awayTeam.name ? preview.match.awayTeam.name : '';
		const coachSelected = checkIfElementSelected(preview.coachParameters && preview.coachParameters.name);
		const firstTeamSelected = checkIfElementSelected(preview.firstTeamParameters && preview.firstTeamParameters.name);
		const firstTeamStateSelected = checkIfElementSelected(preview.firstTeamParameters && preview.firstTeamParameters.status);
		const substitutesSelected = checkIfElementSelected(preview.substitutesParameters && preview.substitutesParameters.name);
		const substitutesStateSelected = checkIfElementSelected(preview.substitutesParameters && preview.substitutesParameters.status);

		return (
			<>
				<div data-qa='football-lineups-view'>
					<Row className='pb-4 pl-4 pr-4'>
						<Col className='text-center'>
							<>
								<Row className='mb-1'>
									<Col>{moment(preview.match.kickoffTime ? preview.match.kickoffTime : '').format('MMM Do YYYY, HH:mm')}</Col>
								</Row>
								<Row className='mb-1'>
									<Col className='text-right'>
										<strong className=' mr-2'>{homeTeamName}</strong>
										<img
											height='40'
											alt={homeTeamName}
											src={
												preview.match.homeTeam.assets && preview.match.homeTeam.assets.logo
													? preview.match.homeTeam.assets.logo.url
													: assetsPlaceholder.team
											}
										/>
									</Col>

									<Col className='text-left'>
										<img
											height='40'
											alt={awayTeamName}
											src={
												preview.match.awayTeam.assets && preview.match.awayTeam.assets.logo
													? preview.match.awayTeam.assets.logo.url
													: assetsPlaceholder.team
											}
										/>
										<strong className=' ml-2'>{awayTeamName}</strong>
									</Col>
								</Row>
							</>
							<Row className='pt-1 mt-2'>
								<Col className='text-left d-flex'>
									<strong>{t('team_colors')}:</strong>
									<div className='ml-2'>
										<svg xmlns='http://www.w3.org/2000/svg' width='30.033' height='25.002' viewBox='0 0 30.033 25.002'>
											<path
												id='shirt'
												d='M349.149,493.75c-1.1.681-1.236,1.216-2.123,1.8-1.138.753-1.5.636-1.988-.723-.649-1.819-1.292-3.644-1.948-5.46-.214-.594-.227-1.129.372-1.457,2.571-1.409,4.382-2.787,7.264-3.3a13.175,13.175,0,0,1,2.932-.06c.651.029.83.54.843,1.2.035,1.795,1.791,2.2,3.488,2.362,1.506.146,3.743-.049,4.107-1.634a3.136,3.136,0,0,0,.073-.5c.086-1.262.264-1.543,1.453-1.372a23.066,23.066,0,0,1,4.548,1.049c1.622.585,2.524,1.465,4.093,2.217a1.089,1.089,0,0,1,.606,1.566c-.71,1.913-1.371,3.848-2.065,5.769-.346.958-.847,1.111-1.66.586-.883-.57-1.154-1.114-2.05-1.661a5.789,5.789,0,0,0-.708-.279c.116.928.187,1.72.319,2.5.215,1.279-.241,6.374-.092,7.66.25,2.151-.333,3.359-2.21,4.178a15.489,15.489,0,0,1-4.046,1.132,17.78,17.78,0,0,1-7.8-.608,12.714,12.714,0,0,1-1.915-.8,3.4,3.4,0,0,1-1.995-3.9c.26-1.556.044-6.933.321-8.486C349.057,495.032,349.073,494.527,349.149,493.75Z'
												transform='translate(-342.956 -484.5)'
												fill={homeTeamColor}
											/>
										</svg>
										<strong className='mr-1 ml-1'>{homeTeamName},</strong>
									</div>
									<div className='ml-2'>
										<svg xmlns='http://www.w3.org/2000/svg' width='30.033' height='25.002' viewBox='0 0 30.033 25.002'>
											<path
												id='shirt'
												d='M349.149,493.75c-1.1.681-1.236,1.216-2.123,1.8-1.138.753-1.5.636-1.988-.723-.649-1.819-1.292-3.644-1.948-5.46-.214-.594-.227-1.129.372-1.457,2.571-1.409,4.382-2.787,7.264-3.3a13.175,13.175,0,0,1,2.932-.06c.651.029.83.54.843,1.2.035,1.795,1.791,2.2,3.488,2.362,1.506.146,3.743-.049,4.107-1.634a3.136,3.136,0,0,0,.073-.5c.086-1.262.264-1.543,1.453-1.372a23.066,23.066,0,0,1,4.548,1.049c1.622.585,2.524,1.465,4.093,2.217a1.089,1.089,0,0,1,.606,1.566c-.71,1.913-1.371,3.848-2.065,5.769-.346.958-.847,1.111-1.66.586-.883-.57-1.154-1.114-2.05-1.661a5.789,5.789,0,0,0-.708-.279c.116.928.187,1.72.319,2.5.215,1.279-.241,6.374-.092,7.66.25,2.151-.333,3.359-2.21,4.178a15.489,15.489,0,0,1-4.046,1.132,17.78,17.78,0,0,1-7.8-.608,12.714,12.714,0,0,1-1.915-.8,3.4,3.4,0,0,1-1.995-3.9c.26-1.556.044-6.933.321-8.486C349.057,495.032,349.073,494.527,349.149,493.75Z'
												transform='translate(-342.956 -484.5)'
												fill={awayTeamColor}
											/>
										</svg>
										<strong className='mr-1 ml-1'>{awayTeamName}</strong>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
					{(coachSelected || firstTeamSelected || substitutesStateSelected) && (
						<Row className='pb-4 pl-4 pr-4'>
							<Col>
								<div>
									<strong className='mr-2'>{t('elements')}:</strong>
									{coachSelected ? t(`${preview.coachParameters.name}_title;`) : ''}
									{firstTeamSelected ? t(`${preview.firstTeamParameters.name}_title;`) : ''}
									{firstTeamStateSelected ? t(`${preview.firstTeamParameters.name}_${preview.firstTeamParameters.status}_title;`) : ''}
									{substitutesSelected ? t(`${preview.substitutesParameters.name}_title;`) : ''}
									{substitutesStateSelected ? t(`${preview.substitutesParameters.name}_${preview.substitutesParameters.status}_title;`) : ''}
								</div>
							</Col>
						</Row>
					)}
					{generateRefreshTimeOptionViewText(preview.refreshTime)}
				</div>
			</>
		);
	}

	return null;
};
