import { assetsPlaceholder } from '../constants/assetsPlaceholder';
import { relatedConstants } from '../constants/related.constants';
import { ContentMode, ContentTypes } from '../constants/content-types';
import { availableContentTypes } from '../services/content-models-service/ContentModelService';

export const capitalize = (string: string) => {
	if (typeof string !== 'string') {
		return '';
	}

	return string.charAt(0).toUpperCase() + string.toLocaleLowerCase().slice(1);
};

export const dateStringAsTimeNumber = (dateString: string): number => {
	// work for 'yyyy-mm-dd' string
	try {
		const [year, month, day] = dateString.split('-');
		const startDate = new Date(+year, +month - 1, +day);
		return startDate != null ? startDate.getTime() : 0;
	} catch {
		return 0;
	}
};

export const minimumCharsRestriction = (text: string, minimumChars: number) => {
	return text.length >= minimumChars;
};

export const maximumCharsRestriction = (text: string, maximumChars: number) => {
	return text.length <= maximumChars;
};

export const isCharRestrictionOkay = (
	text: string,
	minCharsAllowed: number | null = null,
	maxCharsAllowed: number | null = null,
): boolean => {
	if (text) {
		if (minCharsAllowed !== null && maxCharsAllowed !== null) {
			return minimumCharsRestriction(text, minCharsAllowed) && maximumCharsRestriction(text, maxCharsAllowed);
		} else if (minCharsAllowed !== null) {
			return minimumCharsRestriction(text, minCharsAllowed);
		} else if (maxCharsAllowed !== null) {
			return maximumCharsRestriction(text, maxCharsAllowed);
		}
	}
	return false;
};

export const getStartOfTextByCharsCount = (text: string, maximumChars: number): string => {
	if (text.length > maximumChars) {
		return text.substring(0, maximumChars);
	}
	return text;
};

// Pass object/class that you want to check and properties as string for arguments
// (ex: Article, 'image', 'data', 'urls', 'uploaded', 'original')
export const checkObjectPropertiesExists = function (object: object, ...args: string[]) {
	for (var i = 0; i < args.length; i++) {
		if (!object || !object.hasOwnProperty(args[i])) {
			return false;
		}
		object = object[args[i]];
	}
	return true;
};

export const getLastElementsFromArray = (array: Array<any>, elementsCount: number = 0): Array<any> => {
	return [...array].slice(Math.max(array.length - elementsCount, 0));
};

export const addLoaderScript = (url: string, id: string) => {
	const existingScript = document.getElementById(id);
	if (!existingScript) {
		const script = document.createElement('script');
		script.id = id;
		script.src = url;
		script.type = 'module';
		document.head.appendChild(script);
	}
};

export const appendCrazyEggScript = () => {
	// function appending the below script in the head if the environment is production
	// <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0111/2903.js" async="async"></script>
	try {
		if (process.env.NODE_ENV === 'production') {
			const scriptTag = document.createElement('script');
			scriptTag.src = '//script.crazyegg.com/pages/scripts/0111/2903.js';
			scriptTag.type = 'text/javascript';
			scriptTag.async = true;
			document.head.appendChild(scriptTag);
		}
	} catch (error) {}
};

export const generateDefaultImageByEntityType = (entityType: string) => {
	const entityTypes = relatedConstants.types;

	switch (entityType) {
		case entityTypes.arena:
			return assetsPlaceholder.arena;
		case entityTypes.coach:
			return assetsPlaceholder.coach;
		case entityTypes.competition:
			return assetsPlaceholder.competition;
		case entityTypes.player:
			return assetsPlaceholder.player;
		case entityTypes.president:
			return assetsPlaceholder.president;
		case entityTypes.team:
			return assetsPlaceholder.team;
		case entityTypes.tournament:
			return assetsPlaceholder.tournament;
		case entityTypes.venue:
			return assetsPlaceholder.venue;
		default:
			return assetsPlaceholder.blankPlaceholder;
	}
};

export const getCurrentUserUrl = (): string => window.location.hash || window.location.pathname;

export const getLastVisitedLocations = (): string[] => {
	try {
		const sessionStorageLocationItems = sessionStorage.getItem('latestLocation');
		const lastSavedState: string[] = sessionStorageLocationItems ? JSON.parse(sessionStorageLocationItems) : [];
		return lastSavedState;
	} catch {
		return [];
	}
};

export const detectUserLocationChange = () => {
	try {
		window.addEventListener('popstate', function () {
			const lastSavedState: string[] = getLastVisitedLocations();
			const newState: string[] = [...lastSavedState];
			const currentLocation: string = getCurrentUserUrl();
			newState.length >= 3 && newState.shift();
			newState.push(currentLocation);
			sessionStorage.setItem('latestLocation', JSON.stringify(newState));
		});
	} catch {}
};

export const getLastVisitedLocation = (): string | null => {
	try {
		const lastSavedState: string[] = getLastVisitedLocations();
		const lastSavedStateLength = lastSavedState.length;
		return lastSavedStateLength > 0 ? lastSavedState[lastSavedStateLength - 1] : null;
	} catch {
		return null;
	}
};

export const getPreviousVisitedLocation = (): string | null => {
	try {
		const lastSavedState: string[] = getLastVisitedLocations();
		const lastSavedStateLength = lastSavedState.length;
		return lastSavedStateLength > 1 ? lastSavedState[lastSavedStateLength - 2] : null;
	} catch {
		return null;
	}
};

const checkUrlContentModeByUrl = (searchUrl: string): boolean => {
	const currentLocation = getCurrentUserUrl();
	if (currentLocation) {
		const currentLocationLength = currentLocation.length;
		const searchUrlLength = searchUrl.length;
		const diffLength = currentLocationLength - searchUrlLength;

		if (currentLocation.includes(`${searchUrl}/`)) {
			return true;
		} else if (diffLength >= 0) {
			return currentLocation.substring(currentLocationLength - searchUrlLength) === searchUrl;
		}
	}
	return false;
};

export const isEditScreen = (): boolean => checkUrlContentModeByUrl('/edit');

export const isCreateScreen = (): boolean => checkUrlContentModeByUrl('/create');

export const extractContentModeBasedOnUrl = (): ContentMode => {
	return isEditScreen() ? ContentMode.EDIT : ContentMode.CREATE;
};

export const extractMainContentTypeBasedOnUrl = (): availableContentTypes | null => {
	const currentLocation = getCurrentUserUrl();

	if (currentLocation) {
		if (currentLocation.includes('/articles/')) {
			return ContentTypes.ARTICLE;
		} else if (currentLocation.includes('/videos/')) {
			return ContentTypes.VIDEO;
		} else if (currentLocation.includes('/galleries/')) {
			return ContentTypes.GALLERY;
		} else if (currentLocation.includes('/wiki-pages/')) {
			return ContentTypes.WIKI_PAGE;
		} else if (currentLocation.includes('/live-blogs/')) {
			return ContentTypes.LIVE_BLOG;
		}
	}

	return null;
};

export const isObjectEmpty = (object: Record<string | number, any> | null | undefined): boolean => {
	return object ? Object.keys(object).length === 0 : true;
};

export const isArrayEmpty = (array: any[]): boolean => {
	return array.length === 0;
};

// this will not check array or objects
export const isPrimitiveValueEmpty = (value: any): boolean => {
	try {
		return value === undefined || value === null || value.length === 0;
	} catch (error) {
		// Returns false because if a number comes we can't check if it is empty
		return false;
	}
};

export const isValueEmpty = (data: any) => {
	return (Array.isArray(data) && isArrayEmpty(data)) || (typeof data === 'object' && isObjectEmpty(data)) || isPrimitiveValueEmpty(data);
};

export const isTempIdOrEmpty = (contentModelId: string): boolean => {
	return contentModelId === 'temp-id' || contentModelId === 'temp-copy-id' || contentModelId === '';
};
