import slugify from 'slugify';
import { slugRegex } from '../../../SeoConstants';

// Overwrite symbol for slug
slugify.extend({ θ: 'th' });
slugify.extend({ Θ: 'th' });
slugify.extend({ ξ: 'ks' });
slugify.extend({ Ξ: 'ks' });
slugify.extend({ ω: 'o' });
slugify.extend({ Ω: 'o' });

export const isAutoSlugEnabled = (title: string, slug: string): boolean => {
	if (slug === undefined || slug === null) {
		return true;
	}

	if (typeof title === 'string' && slugify(title, '-') === slug) {
		return true;
	}

	return false;
};

export const slugifyTitle = (title: string, slug: string, shouldSlugify: boolean) => {
	if (shouldSlugify && typeof title === 'string') {
		return slugify(title, { replacement: '-', remove: slugRegex, lower: true });
	}

	if (!slug) {
		return '';
	}

	return slug;
};

export const slugifyString = (value: string = '') => {
	if (value) {
		return slugify(value, { replacement: '-', remove: slugRegex, lower: true });
	}

	return '';
};
