import TeamProgrammeWidgetBuilder from './team-programme-widget.builder';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import { TeamProgrammeWidgetJson } from './team-programme-widget.json';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class TeamProgrammeWidgetModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly rounds: RoundModel[];
	readonly team: TeamModel;
	readonly sortDirection: string;
	readonly matchType: any;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		rounds: RoundModel[],
		team: TeamModel,
		sortDirection: string,
		matchType: any,
		refreshTime: RefreshTimeValuesType,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.rounds = rounds;
		this.team = team;
		this.sortDirection = sortDirection;
		this.matchType = matchType;
		this.refreshTime = refreshTime;
	}

	toJson(): TeamProgrammeWidgetJson {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			rounds: this.rounds,
			team: this.team,
			sortDirection: this.sortDirection,
			matchType: this.matchType,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: TeamProgrammeWidgetJson): TeamProgrammeWidgetModel {
		return new TeamProgrammeWidgetModel(
			json.tournament,
			json.season,
			json.stage,
			json.rounds,
			json.team,
			json.sortDirection,
			json.matchType,
			json.refreshTime,
		);
	}

	static builder(model?: TeamProgrammeWidgetModel): TeamProgrammeWidgetBuilder {
		return new TeamProgrammeWidgetBuilder(model);
	}
}
