import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import { customOption } from '../../../partials/shared/custom-select-option';
import ErrorHandler from '../error-handler/error-handler';
import MatchModelV2 from '../../widgets-v2/football-single-event/models/MatchModelV2.model';
import { matchListToOptionList, matchToOption, responseToMatchModelV2 } from './matches-v2/matches-container-v2.helper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { useTranslation } from 'react-i18next';

type Properties = {
	onMatchSelect: Function;
	selectedMatch: MatchModelV2;
	marketValueType: string;
	teamId: string;
	isValid: boolean;
	isRequired?: boolean;
	id: string;
	limit: string;
};

const MatchesByTeamIdComponent: React.FunctionComponent<Properties> = (props) => {
	const [t] = useTranslation();
	const [matchesOptions, setMatchesOptions] = useState<MatchModelV2[]>([]);

	useEffect(() => {
		if (props.teamId) {
			getTeamsById(props.teamId, props.marketValueType, props.limit);
		}
	}, [props.teamId, props.marketValueType]);

	const updateMatchesOptionsState = (matches: MatchModelV2[]) => {
		const { onMatchSelect, selectedMatch } = props;

		if (!selectedMatch) {
			onMatchSelect(matches[0]);
		}
		setMatchesOptions(matches);
	};

	const getTeamsById = (teamId: string, marketValueType: string, limit: string) => {
		const featureConfig = featuresService.getFeatureConfig(FeatureTypes.FOOTBALL_V2);
		const oddClient = featureConfig.oddClient ? featureConfig.oddClient : null;
		if (teamId) {
			return HttpService.getMatchesFromCurrentKickOffDateByTeamId(teamId, marketValueType, oddClient, limit)
				.then((response: any) => {
					updateMatchesOptionsState(response.data.matches.map((item: MatchModelV2) => responseToMatchModelV2(item)));
				})
				.catch((e: any) => e);
		}
	};

	const { isRequired, onMatchSelect, selectedMatch, isValid } = props;
	const matchNotSelected = selectedMatch === undefined || selectedMatch === null;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('match')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					options={matchListToOptionList(matchesOptions)}
					value={selectedMatch && selectedMatch.id ? matchToOption(selectedMatch) : []}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={!matchNotSelected}
					placeholder={t('select')}
					formatOptionLabel={customOption}
					onChange={(selection: SelectMenuOptionType) => {
						selection ? onMatchSelect(selection.data) : onMatchSelect(null);
					}}
				/>
				{isRequired && <ErrorHandler arePropsValid={isValid} isValueSelected={matchNotSelected} t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default MatchesByTeamIdComponent as React.ComponentType<Properties>;
