import slugify from 'slugify';
import { emojiRegex, slugRegex } from '../constants/slug.regex';

// Overwrite symbol for slug
slugify.extend({ θ: 'th' });
slugify.extend({ Θ: 'th' });
slugify.extend({ ξ: 'ks' });
slugify.extend({ Ξ: 'ks' });
slugify.extend({ ω: 'o' });
slugify.extend({ Ω: 'o' });

export const removeTextEmojis = (text: string = '') => {
	return text.replace(emojiRegex(), '');
};

export const slugifyString = (text: string) => {
	const textWithoutEmojis = removeTextEmojis(text);
	return slugify(textWithoutEmojis, { replacement: '-', remove: slugRegex, lower: true });
};
