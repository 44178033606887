import MainMedia from '../../../../../models/mainmedia/MainMedia';

export function addMainMediaIfNotExists(toAdd: MainMedia, mainMediaList: MainMedia[]): MainMedia[] {
	let index: number = 0;

	if (mainMediaList && mainMediaList.length > 0) {
		index = mainMediaList.findIndex((mainMedia: MainMedia) => {
			return toAdd.resourceType === mainMedia.resourceType && toAdd.resourceSubtype === mainMedia.resourceSubtype;
		});

		if (index === -1) {
			mainMediaList.push(toAdd);
		}
	} else {
		if (toAdd) {
			mainMediaList = [toAdd];
		}
	}

	return mainMediaList ? mainMediaList : [];
}

export function removeMainMedia(type: string, subType: string, mainMediaList: MainMedia[]) {
	if (mainMediaList && mainMediaList.length) {
		const index = mainMediaList.findIndex((item: MainMedia) => {
			return type === item.resourceType && subType === item.resourceSubtype;
		});

		if (index !== -1) {
			mainMediaList.splice(index, 1);
		}
	}

	return mainMediaList ? mainMediaList : [];
}

export function extractMainImageMedia(mainMediaList: MainMedia[]): MainMedia {
	if (mainMediaList && mainMediaList.length > 0) {
		let mainImageMedia = mainMediaList.filter((media: MainMedia) => media.resourceType === 'image' && media.resourceSubtype === 'main_image');

		return mainImageMedia && mainImageMedia.length > 0 ? mainImageMedia[0] : ({} as MainMedia);
	}

	return {} as MainMedia;
}

export function updateMainMediaInList(toUpdate: MainMedia, mainMediaList: MainMedia[]) {
	let index: number = 0;

	if (toUpdate && mainMediaList && mainMediaList.length > 0) {
		index = mainMediaList.findIndex((item: MainMedia) => {
			return item.resourceType === toUpdate.resourceType && item.resourceSubtype === toUpdate.resourceSubtype;
		});

		if (index !== -1) {
			mainMediaList[index] = toUpdate;

			return mainMediaList;
		} else {
			mainMediaList.push(toUpdate);
		}
	} else {
		mainMediaList = [];
		toUpdate && mainMediaList.push(toUpdate);
	}

	return mainMediaList;
}
