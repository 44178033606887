import * as Sentry from '@sentry/react';
import { TOKEN_USER_ID } from '../../constants/constants';
import REACT_APP_URLS, { Environments } from '../../global-helpers/global-url.helpers';
import Project from '../../models/project/Project';

class SentryService {
	private static initialized = false;

	// Modify this variable to enable Sentry for localhost
	private static isLocalhostEnabled = false;

	// Modify this array to enable Sentry for specific environments only
	private static environments: Environments[] = [
		Environments.INTEGRATION,
		Environments.STAGING,
		Environments.SANDBOX,
		Environments.PRODUCTION,
	];

	private static get isCurrentEnvironmentSentryEnabled() {
		if (window.location.hostname === 'localhost' && !this.isLocalhostEnabled) {
			return false;
		}

		return this.environments.includes(REACT_APP_URLS.REACT_APP_ENV);
	}

	static init() {
		if (this.initialized || !this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		Sentry.init({
			dsn: 'https://db64c56109d07248bfd3b9ab842e75f2@o1167176.ingest.sentry.io/4506218890788864',
			integrations: [
				new Sentry.BrowserTracing({
					// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
					tracePropagationTargets: ['localhost'],
				}),
			],
			// Performance Monitoring
			tracesSampleRate: 1.0, // Capture 100% of the transactions
			environment: REACT_APP_URLS.REACT_APP_ENV,
		});
		this.setUser();
		this.setProject();

		this.initialized = true;
	}

	static setUser(userId?: string | null) {
		if (!this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		let userIdToAssign = userId;

		if (!userIdToAssign) {
			userIdToAssign = localStorage.getItem(TOKEN_USER_ID);
		}

		Sentry.setUser(userIdToAssign ? { id: userIdToAssign } : null);

		if (userIdToAssign) {
			Sentry.setTag('user_id', userIdToAssign);
		}
	}

	static setProject(projectId?: string) {
		if (!this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		let projectIdToAssign = projectId;

		if (!projectIdToAssign) {
			try {
				const state = localStorage.getItem('persist:root');

				if (state) {
					const project: string = JSON.parse(state).project;

					if (project) {
						projectIdToAssign = (JSON.parse(project).currentProject as Project).domain;
					}
				}
			} catch (e) {}
		}

		if (!projectIdToAssign) {
			return;
		}

		this.setContext('Project', { id: projectIdToAssign });

		if (projectIdToAssign) {
			Sentry.setTag('project_id', projectIdToAssign);
		}
	}

	static setContext(key: string, value: any) {
		if (!this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		Sentry.configureScope((scope) => scope.setContext(key, value));
	}

	static removeContext(key: string) {
		if (!this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		this.setContext(key, null);
	}

	static captureException(error: unknown) {
		if (!this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		Sentry.captureException(error);
	}

	static captureMessage(message: string) {
		if (!this.isCurrentEnvironmentSentryEnabled) {
			return;
		}

		Sentry.captureMessage(message);
	}
}

export default SentryService;
