import OddsV2Model from '../models/v2/odds-v2.model';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import BookmakerModelV2 from '../../../partials/bookmaker-select/models/bookmaker.model';

export const buildInitialModel = (currentOdds: OddsV2Model, match: MatchModelV2) => {
	const event = match ? match : new MatchModelV2();
	let oddsModel = new OddsV2Model();
	if (currentOdds) {
		oddsModel = currentOdds;
		oddsModel.match = event;
	} else {
		oddsModel.match = event;
		oddsModel.mainBookmaker = null;
		oddsModel.bookmakers = [];
		oddsModel.market = { name: '1x2', value: '1x2' };
		oddsModel.canSelectMarkets = false;
		oddsModel.displayMatchHeader = false;
	}

	return oddsModel;
};
