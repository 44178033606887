import React, { useEffect, useState } from 'react';
import HttpService from '../../../../../../../services/rest/HttpService';
import {
	convertTeamIdsToMatchSearchUrls,
	markMatchModelAsSelected,
	MATCH_STATUS_TYPES,
	removePastMatches,
	responseToMatchModelV2,
} from './matches-container-v2.helper';
import MatchModelV2 from '../../../widgets-v2/football-single-event/models/MatchModelV2.model';
import { MatchesV2 } from './matches-v2';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';

type Properties = {
	teamIds: string[];
	isMulti: boolean;
	teamNames: string[];
	maxMatches: number;
	selectedMatchIds?: string[];
	selectedMatchId: string;
	onSelect: any;
	round?: RoundModel;
	stageId?: string;
	updateCheckAllMatchesState?: any;
	upcomingMatchesAreSelected?: any;
	finishedMatchesAreSelected?: any;
	hideMatchStatusTabs?: boolean;
	interruptedMatchesAreSelected?: any;
	t: any;
};

export const MatchesContainerV2: React.FunctionComponent<Properties> = (props) => {
	const [matchesFinished, setMatchesFinished] = useState<MatchModelV2[]>([]);
	const [matchesNotStarted, setMatchesNotStarted] = useState<MatchModelV2[]>([]);
	const [matchesInterrupted, setMatchesInterrupted] = useState<MatchModelV2[]>([]);
	const [selectedTeamNames, setSelectedTeamNames] = useState<string[]>([]);
	const [isContentLoading, setIsContentLoading] = useState<boolean>(false);
	const [currentSelectedTeamsIds, setCurrentSelectedTeamsIds] = useState<string[]>([]);

	useEffect(() => {
		const { isMulti, selectedMatchId, selectedMatchIds, round } = props;

		if (round && round.id) {
			matchesByRound(selectedMatchId, selectedMatchIds, isMulti);
		}
	}, []);

	useEffect(() => {
		const { teamIds, teamNames, selectedMatchId, selectedMatchIds } = props;
		const resetToInitialState = () => {
			setMatchesFinished([]);
			setMatchesNotStarted([]);
			setMatchesInterrupted([]);
			setSelectedTeamNames([]);
			setIsContentLoading(false);
		};

		if (teamIds && JSON.stringify(currentSelectedTeamsIds) !== JSON.stringify(teamIds)) {
			setSelectedTeamNames(teamNames);
			setCurrentSelectedTeamsIds(teamIds);

			if (teamIds && teamIds.length < 3 && teamIds.length > 0) {
				getMatches(convertTeamIdsToMatchSearchUrls(teamIds, props.maxMatches), selectedMatchId, selectedMatchIds, props.isMulti);
			}

			if (teamIds && teamIds.length < 1) {
				resetToInitialState();
			}
		}
	}, [props.teamIds]);

	useEffect(() => {
		const { selectedMatchId, selectedMatchIds, isMulti } = props;

		if ((selectedMatchId && selectedMatchId !== '') || (selectedMatchIds && selectedMatchIds.length > 0)) {
			let finished = markMatchModelAsSelected(matchesFinished, selectedMatchId, selectedMatchIds, isMulti);
			let notStarted = markMatchModelAsSelected(matchesNotStarted, selectedMatchId, selectedMatchIds, isMulti);
			let interrupted = markMatchModelAsSelected(matchesInterrupted, selectedMatchId, selectedMatchIds, isMulti);

			setMatchesFinished(finished);
			setMatchesNotStarted(notStarted);
			setMatchesInterrupted(interrupted);
		}
	}, [props.selectedMatchId, props.selectedMatchIds, props.isMulti]);

	const getMatches = (urls: string[], selectedMatchId: string, selectedMatchIds: string[], isMulti: boolean) => {
		const { hideMatchStatusTabs } = props;
		setIsContentLoading(true);

		if (hideMatchStatusTabs) {
			HttpService.requestMultipleFootballRequests([urls[0], urls[2]])
				.then((response: any) => {
					let notStarted = response[0].data.matches.map((item: any) => responseToMatchModelV2(item));
					let progress = response[1].data.matches.map((item: any) => responseToMatchModelV2(item));

					notStarted = [...progress, ...removePastMatches([...notStarted])];
					notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
					setMatchesNotStarted(notStarted);
					setIsContentLoading(false);
				})
				.catch((e: any) => {
					setIsContentLoading(false);

					return e;
				});
		} else {
			HttpService.requestMultipleFootballRequests([...urls])
				.then((response: any) => {
					let notStarted = response[0].data.matches.map((item: any) => responseToMatchModelV2(item));
					let finished = response[1].data.matches.map((item: any) => responseToMatchModelV2(item));
					let progress = response[2].data.matches.map((item: any) => responseToMatchModelV2(item));
					let interrupted = response[3].data.matches.map((item: any) => responseToMatchModelV2(item));

					notStarted = [...progress, ...removePastMatches([...notStarted])];
					notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
					finished = markMatchModelAsSelected(finished, selectedMatchId, selectedMatchIds, isMulti);
					interrupted = markMatchModelAsSelected(interrupted, selectedMatchId, selectedMatchIds, isMulti);

					setMatchesNotStarted(notStarted);
					setMatchesFinished(finished);
					setMatchesInterrupted(interrupted);
					setIsContentLoading(false);
				})
				.catch((e: any) => {
					setIsContentLoading(false);

					return e;
				});
		}
	};

	const matchesByRound = (selectedMatchId: string, selectedMatchIds: string[], isMulti: boolean) => {
		const { maxMatches } = props;
		setIsContentLoading(true);

		HttpService.getRoundEvents(props.stageId, props.round.id)
			.then((response: any) => {
				let notStarted = response.data.matches
					.filter((item: any) => item.status.type === MATCH_STATUS_TYPES.not_started)
					.map((item: any) => responseToMatchModelV2(item));
				let finished = response.data.matches
					.filter((item: any) => item.status.type === MATCH_STATUS_TYPES.finished)
					.map((item: any) => responseToMatchModelV2(item));
				let progress = response.data.matches
					.filter((item: any) => item.status.type === MATCH_STATUS_TYPES.live)
					.map((item: any) => responseToMatchModelV2(item));
				let interrupted = response.data.matches
					.filter((item: any) => item.status.type === MATCH_STATUS_TYPES.interrupted)
					.map((item: any) => responseToMatchModelV2(item));

				notStarted = [...progress, ...removePastMatches([...notStarted])];
				notStarted = markMatchModelAsSelected(notStarted, selectedMatchId, selectedMatchIds, isMulti);
				finished = markMatchModelAsSelected(finished, selectedMatchId, selectedMatchIds, isMulti);
				interrupted = markMatchModelAsSelected(interrupted, selectedMatchId, selectedMatchIds, isMulti);

				setMatchesFinished(finished);
				setMatchesNotStarted(notStarted);
				setMatchesInterrupted(interrupted);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				setIsContentLoading(false);

				return e;
			});
	};

	return (
		<div className={`${isContentLoading ? 'loading-overlay' : ''}`} data-qa='matches-container'>
			<MatchesV2
				selectedTeamNames={selectedTeamNames}
				t={props.t}
				hideMatchStatusTabs={props.hideMatchStatusTabs}
				isMulti={props.isMulti}
				onMatchSelect={props.onSelect}
				onMatchesSelect={props.onSelect}
				matchesFinished={matchesFinished}
				matchesNotStarted={matchesNotStarted}
				matchesInterrupted={matchesInterrupted}
				updateCheckAllMatchesState={props.updateCheckAllMatchesState}
				upcomingMatchesAreSelected={props.upcomingMatchesAreSelected}
				finishedMatchesAreSelected={props.finishedMatchesAreSelected}
				interruptedMatchesAreSelected={props.interruptedMatchesAreSelected}
			/>
		</div>
	);
};
