import { FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import React from 'react';
import MatchModelV2 from '../../../widgets-v2/football-single-event/models/MatchModelV2.model';

type Properties = {
	isMulti: boolean;
	onMatchSelect: (match: MatchModelV2) => void;
	onMatchesSelect: any;
	matchesNotStarted: MatchModelV2[];
	notStartedMatchesAreSelected: boolean;
	t: any;
	applyBoldCss: (teamName: string) => void;
	selectAllMatches: (matches: MatchModelV2[], event: any) => void;
	toggleGoals: (match: MatchModelV2) => void;
	applyInProgressClass: (type: string) => void;
};

export const MatchesNotStartedV2: React.FunctionComponent<Properties> = (props) => {
	if (props.matchesNotStarted.length < 1) {
		return <div className='text-center p-3'>{props.t('no_matches_found')}</div>;
	}

	return (
		<>
			<div data-qa='matches-not-started-tab'>
				{props.isMulti && (
					<FormGroup className='mb-2 pb-2 border-bottom' check>
						<Input
							className='form-check-input'
							onChange={(event) => props.selectAllMatches(props.matchesNotStarted, event)}
							type='checkbox'
							checked={props.matchesNotStarted[0].isSelected && props.notStartedMatchesAreSelected}
							id={`select-all-not-started-matches${props.matchesNotStarted.length}`}
							name={props.t('select_all_matches')}
						/>
						<Label className='form-check-label' check htmlFor={`select-all-not-started-matches${props.matchesNotStarted.length}`}>
							{props.t('select_all_matches')}
						</Label>
					</FormGroup>
				)}
				{props.matchesNotStarted.map((match) => {
					return (
						<FormGroup key={`key-${match.id}`} className={`mb-2 pb-2 border-bottom ${props.applyInProgressClass(match.status.type)}`} check>
							<Input
								className='form-check-input'
								onChange={(event) => {
									if (props.isMulti) {
										props.onMatchesSelect(match, event);
									} else {
										props.onMatchSelect(match);
									}
								}}
								type={props.isMulti ? 'checkbox' : 'radio'}
								id={`${match.id}`}
								name={`inline-radio-${match.id}`}
								checked={match.isSelected}
							/>
							<Label className='form-check-label' check htmlFor={`${match.id}`}>
								{`${moment(match.kickoffTime).format('DD MMM YYYY')} - `}
								<span className={`${props.applyBoldCss(match.homeTeam.name)}`}>{match.homeTeam.name}</span>
								{props.toggleGoals(match)}
								<span className={`${props.applyBoldCss(match.awayTeam.name)}`}>{match.awayTeam.name}</span>
							</Label>
						</FormGroup>
					);
				})}
			</div>
		</>
	);
};

export default MatchesNotStartedV2 as React.ComponentType<Properties>;
