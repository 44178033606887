import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import MatchModelV2 from '../../../widgets-v2/football-single-event/models/MatchModelV2.model';
import { MatchesNotStartedV2 } from './marches-not-started-tab-v2';
import { MatchesFinishedV2 } from './matches-finished-tab-v2';
import { MatchesInterruptedV2 } from './matches-interrupted-tab-v2';
import { MATCH_STATUS_TYPES } from './matches-container-v2.helper';

type Properties = {
	selectedTeamNames: string[];
	isMulti: boolean;
	onMatchSelect: (match: MatchModelV2) => void;
	onMatchesSelect: (matches: MatchModelV2[]) => void;
	matchesFinished: MatchModelV2[];
	matchesNotStarted: MatchModelV2[];
	matchesInterrupted: MatchModelV2[];
	hideMatchStatusTabs?: boolean;
	updateCheckAllMatchesState: any;
	finishedMatchesAreSelected: any;
	upcomingMatchesAreSelected?: any;
	interruptedMatchesAreSelected?: any;
	t: any;
};

export const MatchesV2: React.FunctionComponent<Properties> = (props) => {
	const { matchesFinished, matchesNotStarted, matchesInterrupted, isMulti, t, hideMatchStatusTabs } = props;

	const [activeTab, setActiveTab] = useState(new Array(4).fill('1'));
	let [matchesState, setMatchesState] = useState(
		[...props.matchesFinished, ...props.matchesNotStarted, ...props.matchesInterrupted].filter((match) => match.isSelected),
	);

	useEffect(() => {
		setMatchesState([...props.matchesFinished, ...props.matchesNotStarted, ...props.matchesInterrupted].filter((match) => match.isSelected));
	}, [props.matchesFinished, props.matchesNotStarted, props.matchesInterrupted]);

	const toggle = (tabPane: number, tab: string) => {
		const newArray = activeTab.slice();
		newArray[tabPane] = tab;

		setActiveTab(newArray);
	};

	const onMatchSelect = (match: MatchModelV2) => {
		props.onMatchSelect(match);
	};

	const handleMatchCheck = (match: MatchModelV2, matches: MatchModelV2[], checked: boolean) => {
		if (checked) {
			matches.push(match);
		} else {
			matches = matches.filter((item) => item.id !== match.id);
		}

		return matches;
	};

	const handleMatchesCheck = (matches: MatchModelV2[], checked: boolean) => {
		updateCheckAllMatchesState(checked, matches);

		let selectedMatches = [];

		if (!checked) {
			let matchIds = matches.concat(matches).map((match: MatchModelV2) => match.id);
			const onlyUnique = (value: any, index: any, self: any) => self.indexOf(value) === index;
			const uniqueIds = matchIds.filter(onlyUnique);

			selectedMatches = matchesState.filter((match: MatchModelV2) => !uniqueIds.includes(match.id));
		} else {
			selectedMatches = matchesState.concat(matches);
		}

		return selectedMatches;
	};

	const updateMatchesState = (matches: MatchModelV2[]) => setMatchesState(matches);

	const updateCheckAllMatchesState = (checked: boolean, selectedMatches: MatchModelV2[]) => {
		if (activeTab[0] === '1') {
			props.updateCheckAllMatchesState(checked, MATCH_STATUS_TYPES.not_started, selectedMatches, activeTab[0]);
		} else {
			props.updateCheckAllMatchesState(checked, MATCH_STATUS_TYPES.finished, selectedMatches, activeTab[0]);
		}
	};

	const onMatchesSelect = (match: MatchModelV2, event: any) => {
		matchesState = handleMatchCheck(match, matchesState, event.target.checked);

		updateMatchesState(matchesState);
		updateCheckAllMatchesState(false, matchesState);

		if (props.onMatchesSelect) {
			props.onMatchesSelect(matchesState);
		}
	};

	const selectAllMatches = (selectedMatches: any, event: any) => {
		selectedMatches = handleMatchesCheck(selectedMatches, event.target.checked);

		if (props.onMatchesSelect) {
			props.onMatchesSelect(selectedMatches);
		}
	};

	const applyBoldCss = (teamName: string) => {
		return props.selectedTeamNames.includes(teamName) ? 'font-weight-bold' : '';
	};

	const applyInProgressClass = (type: string) => {
		return type === MATCH_STATUS_TYPES.live ? 'border-pulsate-right' : '';
	};

	const printMinutesIfMatchInProgress = (match: MatchModelV2) => {
		return match.status.type === MATCH_STATUS_TYPES.live
			? `(${match.minute.injuryTime && match.minute.injuryTime > 0 ? match.minute.injuryTime : match.minute.regularTime}")`
			: '';
	};

	const toggleGoals = (match: MatchModelV2) => {
		return match.status.type === MATCH_STATUS_TYPES.live ? ` ${match.score.total.home}:${match.score.total.away} ` : ' - ';
	};

	return (
		<div id='matches-v2' data-qa='matches-v2'>
			<Row>
				<Col>
					<Nav tabs>
						<NavItem>
							<NavLink active={activeTab[0] === '1'} onClick={() => toggle(0, '1')}>
								<i className='fa fa-calendar' /> {t('upcoming_matches')}
							</NavLink>
						</NavItem>
						<NavItem>
							{!hideMatchStatusTabs && (
								<NavLink active={activeTab[0] === '2'} onClick={() => toggle(0, '2')}>
									<i className='fa fa-calendar-times-o' /> {t('past_matches')}
								</NavLink>
							)}
						</NavItem>
						<NavItem>
							{!hideMatchStatusTabs && (
								<NavLink active={activeTab[0] === '3'} onClick={() => toggle(0, '3')}>
									<i className='fa fa-calendar-minus-o' /> {t('interrupted_matches')}
								</NavLink>
							)}
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab[0]}>
						<TabPane tabId='1' className='p-2 pb-0 bg-white'>
							<FormGroup className='mt-1'>
								<Scrollbars style={{ height: 300 }}>
									<MatchesNotStartedV2
										matchesNotStarted={matchesNotStarted}
										t={t}
										isMulti={false}
										onMatchesSelect={onMatchesSelect}
										onMatchSelect={onMatchSelect}
										applyBoldCss={applyBoldCss}
										selectAllMatches={selectAllMatches}
										notStartedMatchesAreSelected={props.upcomingMatchesAreSelected}
										toggleGoals={toggleGoals}
										applyInProgressClass={applyInProgressClass}
									/>
								</Scrollbars>
							</FormGroup>
						</TabPane>
						<TabPane tabId='2' className='p-2 pb-0 bg-white'>
							{!hideMatchStatusTabs && (
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>
										<MatchesFinishedV2
											matchesFinished={matchesFinished}
											t={t}
											isMulti={false}
											onMatchesSelect={onMatchesSelect}
											onMatchSelect={onMatchSelect}
											applyBoldCss={applyBoldCss}
											selectAllMatches={selectAllMatches}
											finishedMatchesAreSelected={props.finishedMatchesAreSelected}
										/>
									</Scrollbars>
								</FormGroup>
							)}
						</TabPane>
						<TabPane tabId='3' className='p-2 pb-0 bg-white'>
							{!hideMatchStatusTabs && (
								<FormGroup className='mt-1'>
									<Scrollbars style={{ height: 300 }}>
										<MatchesInterruptedV2
											matchesInterrupted={matchesInterrupted}
											t={t}
											isMulti={false}
											onMatchesSelect={onMatchesSelect}
											onMatchSelect={onMatchSelect}
											applyBoldCss={applyBoldCss}
											selectAllMatches={selectAllMatches}
											interruptedMatchesAreSelected={props.interruptedMatchesAreSelected}
											toggleGoals={toggleGoals}
											applyInProgressClass={applyInProgressClass}
											printMinutesIfMatchInProgress={printMinutesIfMatchInProgress}
										/>
									</Scrollbars>
								</FormGroup>
							)}
						</TabPane>
					</TabContent>
				</Col>
			</Row>
		</div>
	);
};
