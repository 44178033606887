import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamMatchStatsJson } from './football-team-match-stats.json';
import FootballMatchStatsBuilder from './football-match-stats.builder';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class FootballTeamMatchStatsWidgetModel {
	readonly team1: TeamModel;
	readonly team2: TeamModel;
	readonly team1TournamentSeason: EntitySeasonsSelectModel;
	readonly team2TournamentSeason: EntitySeasonsSelectModel;
	readonly stats: string[];
	readonly match: MatchModelV2;
	readonly displayOdds: boolean = false;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		team1: TeamModel,
		team2: TeamModel,
		team1TournamentSeason: EntitySeasonsSelectModel,
		team2TournamentSeason: EntitySeasonsSelectModel,
		stats: string[],
		match: MatchModelV2,
		displayOdds: boolean,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team1 = team1;
		this.team2 = team2;
		this.team1TournamentSeason = team1TournamentSeason;
		this.team2TournamentSeason = team2TournamentSeason;
		this.stats = stats;
		this.match = match;
		this.displayOdds = displayOdds;
		this.refreshTime = refreshTime;
	}

	toJson(): FootballTeamMatchStatsJson {
		return {
			team1: this.team1,
			team2: this.team2,
			team1TournamentSeason: this.team1TournamentSeason,
			team2TournamentSeason: this.team2TournamentSeason,
			stats: this.stats,
			match: this.match,
			displayOdds: this.displayOdds,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: FootballTeamMatchStatsJson): FootballTeamMatchStatsWidgetModel {
		return new FootballTeamMatchStatsWidgetModel(
			json.team1,
			json.team2,
			json.team1TournamentSeason,
			json.team2TournamentSeason,
			json.stats,
			json.match,
			json.displayOdds,
			json.refreshTime,
		);
	}

	static builder(model?: FootballTeamMatchStatsWidgetModel): FootballMatchStatsBuilder {
		return new FootballMatchStatsBuilder(model);
	}
}
