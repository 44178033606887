import { Properties, State } from './properties/ImageListingContainerProps';
import * as React from 'react';
import ImageListing from './ImageListing';
import HttpService from '../../../../../../../../services/rest/HttpService';
import Image from '../../../../../../../../models/image/Image';
import ModelMapper from '../../../../../../../../models/ModelMapper';
import PaginationMeta from '../../../../../../../../models/pagination/PaginationMeta';
import { Subscription } from 'rxjs';
import { actionService } from '../../../../../../../../App';

export default class ImageListingContainer extends React.Component<Properties, State> {
	imagesUpdateSub: Subscription = {} as Subscription;

	constructor(props: Properties) {
		super(props);
		this.state = {
			imagesLoading: true,
			pagination: {} as PaginationMeta,
			images: [],
			isContentLoading: false,
		};
	}

	componentWillUnmount(): void {
		this.imagesUpdateSub.unsubscribe();
	}

	componentDidMount(): void {
		if (!this.props.withToggle) {
			this.requestImages();
		}
		this.registerImagesUpdatedListener();
	}

	registerImagesUpdatedListener() {
		this.imagesUpdateSub = window.entityUpdateService.onImagesUpdated().subscribe(() => {
			this.requestImages();
		});
	}

	onImagesOpen = (areOpen: boolean) => {
		if (areOpen) {
			this.requestImages();
		}
	};

	updateImages(images: Image[], pagination: PaginationMeta) {
		this.setState({
			...this.state,
			images,
			pagination,
		});
	}

	responseToImages(response: any): Image[] {
		return ModelMapper.remapImageListFromResponse(response);
	}

	responseToMetaPagination(response: any): PaginationMeta {
		return ModelMapper.remapMetaPagination(response);
	}

	onImageSelect = (image: Image) => {
		this.props.onImageSelect(image);
	};

	onImageSearch = (title: string, page: string) => {
		this.updateImages([], {} as PaginationMeta);

		if (title && title.length > 0) {
			this.searchImages(title, page);
		} else {
			this.requestImages();
		}
	};

	onPageChange = (title?: string, page?: string) => {
		this.updateImages([], {} as PaginationMeta);

		if (title && title.length > 0) {
			this.searchImages(title, page);
		} else {
			this.requestImages(page);
		}
	};

	requestImages(page?: string) {
		this.setState({ ...this.state, isContentLoading: true });

		page = page ? page : '1';
		const headers = { Project: this.props.currentProject.domain };
		HttpService.get(`/images?page=${page}`, null, headers)
			.then((response: any) => {
				const images = this.responseToImages(response.data.data);
				const pagination = this.responseToMetaPagination(response.data.meta.pagination);
				this.updateImages(images, pagination);
				this.setState({ ...this.state, isContentLoading: false });
			})
			.catch((error: any) => {
				this.setState({ ...this.state, isContentLoading: false });
				actionService.emitError(error);
			});
	}

	searchImages(title: string, page?: string) {
		this.setState({ ...this.state, isContentLoading: true });

		page = page || page === '0' ? page : '1';
		const headers = { Project: this.props.currentProject.domain };
		HttpService.get(`/v2/images/search?query=${title}&page=${page}`, null, headers)
			.then((response: any) => {
				const images = this.responseToImages(response.data.data);
				const pagination = this.responseToMetaPagination(response.data.meta.pagination);
				this.updateImages(images, pagination);
				this.setState({ ...this.state, isContentLoading: false });
			})
			.catch((error: any) => {
				this.setState({ ...this.state, isContentLoading: false });
				actionService.emitError(error);
			});
	}

	render() {
		const { t, withToggle } = this.props;
		const { images, pagination, isContentLoading } = this.state;

		return (
			<ImageListing
				onPageChange={this.onPageChange}
				t={t}
				images={images}
				pagination={pagination}
				withToggle={withToggle}
				isContentLoading={isContentLoading}
				onImageSearch={this.onImageSearch}
				onImageSelect={this.onImageSelect}
				onImagesOpen={this.onImagesOpen}
				contentType={this.props.contentType}
			/>
		);
	}
}
