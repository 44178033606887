import React, { ChangeEvent, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { checkForWatermarkFeature, getQuickWatermark } from '../../../../../../services/watermark-service/watermark-helper';
import { DebounceInput } from 'react-debounce-input';
import { extractContentModeBasedOnUrl, extractMainContentTypeBasedOnUrl } from '../../../../../../global-helpers/global.helpers';
import {
	extractMainImageObjectFromArray,
	populateMainMediaDefaultAttributes,
	isWatermarkAvailableForContent,
	isCustomDataPopulatedWithWatermark,
} from '../../helpers/general';
import MainMediaModel from '../../../../../../models/v2/main-media/main-media.model';
import {
	autoGenerateReduxPropsNamingForContentModels,
	generateReduxPropsUpdateActionByContentType,
} from '../../../../../../services/content-models-service/ContentModelService';
import { onMainImageMediaUpdate } from '../../helpers/redux-updater';
import { watermarkProp } from '../../constants/redux-main-media.attributes';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa.attributes';
import ImageDisplayContainer from './image-display';

type Properties = {
	mainMediaRedux: MainMediaModel[];
	watermarkAdded: boolean;
	t: any;
	updateWatermark: (checked: boolean) => void;
};

const MainImage: React.FunctionComponent<Properties> = ({ t, mainMediaRedux, watermarkAdded, updateWatermark }) => {
	const media: MainMediaModel = extractMainImageObjectFromArray(mainMediaRedux) || new MainMediaModel();
	const contentType = extractMainContentTypeBasedOnUrl();
	const isQuickWatermarkEnabled = checkForWatermarkFeature() && getQuickWatermark() && isWatermarkAvailableForContent(contentType);
	const [addDescriptionToImage, setAddDescriptionToImage] = useState(false);

	const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
		const descriptionToImage = !!(media && event.target.value !== '');
		const newMainMedia = populateMainMediaDefaultAttributes({
			...media,
			description: event.target.value,
			addWatermarkToImageMedia: watermarkAdded,
			addDescriptionToMedia: descriptionToImage,
		});

		onMainImageMediaUpdate(newMainMedia);
		setAddDescriptionToImage(descriptionToImage);
	};

	const onAddDescriptionToImageChecked = (e: ChangeEvent<HTMLInputElement>) => {
		const newMainMedia = populateMainMediaDefaultAttributes({
			...media,
			addWatermarkToImageMedia: watermarkAdded,
			addDescriptionToMedia: e.target.checked,
		});

		onMainImageMediaUpdate(newMainMedia);
		setAddDescriptionToImage(e.target.checked);
	};

	const onApplyQuickWatermarkChecked = (e: ChangeEvent<HTMLInputElement>) => updateWatermark(e.target.checked);

	return (
		<>
			<Row>
				<Col>
					<Label className='font-weight-bold'>{t('main_image')}</Label>
				</Col>
			</Row>
			<Row>
				<Col col='12' sm='12' md='6' className='mb-2'>
					<ImageDisplayContainer />
				</Col>
				<Col col='12' sm='12' md='6' className='mb-4'>
					<FormGroup>
						<Label htmlFor={DATA_QA_ATTRIBUTES.MAIN_IMAGE_DESCRIPTION}>{t('image_description')}</Label>
						<DebounceInput
							name='image_description'
							id={DATA_QA_ATTRIBUTES.MAIN_IMAGE_DESCRIPTION}
							element='textarea'
							rows='3'
							value={media && media.description ? media.description : ''}
							onChange={onDescriptionChange}
							className='form-control-lg form-control'
							placeholder={t('image_description_placeholder')}
							debounceTimeout={300}
							disabled={!media.resourceId}
						/>
					</FormGroup>
					<Row className={'mb-1'}>
						<Col>
							<input
								className={'mr-1'}
								id={DATA_QA_ATTRIBUTES.MAIN_IMAGE_DESCRIPTION_CHECKBOX}
								disabled={!(media && media.resourceType)}
								checked={addDescriptionToImage}
								onChange={onAddDescriptionToImageChecked}
								type={'checkbox'}
							/>
							<label htmlFor={DATA_QA_ATTRIBUTES.MAIN_IMAGE_DESCRIPTION_CHECKBOX}>{t('add_description_to_image')}</label>
						</Col>
					</Row>
					{isQuickWatermarkEnabled && (
						<Row className={'mb-1'}>
							<Col>
								<input
									checked={watermarkAdded}
									className={'mr-1'}
									type='checkbox'
									id={DATA_QA_ATTRIBUTES.MAIN_IMAGE_WATERMARK_CHECKBOX}
									onChange={onApplyQuickWatermarkChecked}
								/>
								<label htmlFor={DATA_QA_ATTRIBUTES.MAIN_IMAGE_WATERMARK_CHECKBOX}>{t('quick_watermark')}</label>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</>
	);
};

function mapStateToProps(state: any) {
	const { tempPropertyNaming, nestedTempPropertyNaming } = autoGenerateReduxPropsNamingForContentModels();
	const genericRedux = state[tempPropertyNaming][nestedTempPropertyNaming].generic || null;

	return {
		mainMediaRedux: state[tempPropertyNaming][nestedTempPropertyNaming].mainMedia || [],
		watermarkAdded: isCustomDataPopulatedWithWatermark(genericRedux),
	};
}

function mapDispatchToProps(dispatch: Function) {
	const contentTypeFromUrl = extractMainContentTypeBasedOnUrl();
	const functionForDispatch = generateReduxPropsUpdateActionByContentType(contentTypeFromUrl);

	return {
		updateWatermark: (checked: boolean) => {
			const genericData = { generic: { [watermarkProp]: checked } };
			functionForDispatch && dispatch(functionForDispatch(genericData, extractContentModeBasedOnUrl()));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainImage);
