import React, { useEffect, useState } from 'react';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import MarketSelect from '../../../../partials/market-select/market-select.component';
import { BookmakerSelectComponent } from '../../../partials/bookmaker-select/bookmaker-select.component';
import OddsModelV2 from '../models/odds-widget.model';
import BlockModel from '../../../../models/block.model';
import ErrorHandler from '../../../partials/error-handler/error-handler';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import MarketModel from '../models/v2/market.model';
import { marketOptionsV2 } from '../../../../partials/market-select/helpers/market-select.helpers';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

type Properties = {
	block: BlockModel;
	t: any;
	onOddsWidgetChange?: any;
	match?: MatchModelV2;
	isValid: boolean;
	currentOdds?: OddsModelV2;
	widgetType?: string;
	displayOddsLabel: boolean;
	isStandAlone: boolean;
	displayHeader: boolean;
	selectMainBookmaker?: boolean;
	displayMarketSelection?: boolean;
};

export const OddsEditComponent: React.FunctionComponent<Properties> = (props) => {
	const match = props.match ? props.match : new MatchModelV2();
	const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
	const [oddsWidgetModel, setOddsWidgetModel] = useState<OddsModelV2>(
		props.currentOdds
			? OddsModelV2.builder(props.currentOdds)
					.withMatch(props.currentOdds.match ? props.currentOdds.match : match)
					.build()
			: OddsModelV2.builder()
					.withMatch(match)
					.withBookmakers(null)
					.withMarket({ name: '1x2', value: '1x2' })
					.withCanSelectMarkets(false)
					.withDisplayHeader(false)
					.build(),
	);

	useEffect(() => {
		if (props.onOddsWidgetChange && props.match) {
			updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withMatch(props.match).build());
		}
	}, [props.match, props.onOddsWidgetChange]);

	const updateWidgetModelState = (model: OddsModelV2) => {
		if (!props.isStandAlone) {
			props.onOddsWidgetChange(model);
		}

		setOddsWidgetModel(model);
	};

	const onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		if (bookmakers.length === 0) {
			updateWidgetModelState(
				OddsModelV2.builder(oddsWidgetModel)
					.withBookmakers(bookmakers)
					.withMainBookmaker({} as SportOddsBookmakerModel)
					.withMarket({} as MarketModel)
					.build(),
			);
		} else {
			updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withBookmakers(bookmakers).build());
		}
	};

	const onMainBookmakerChange = (bookmaker: SportOddsBookmakerModel) => {
		updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withMainBookmaker(bookmaker).build());
	};

	const onDisplayHeaderChange = (e: any) => {
		updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withDisplayHeader(e.target.checked).build());
	};

	const onCanSelectMarketsChange = (e: any) => {
		updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withCanSelectMarkets(e.target.checked).build());
	};

	const onMarketChange = (marketName: string) => {
		updateWidgetModelState(OddsModelV2.builder(oddsWidgetModel).withMarket({ name: marketName, value: marketName }).build());
	};

	return (
		<>
			<div data-qa={`${SportTypes.FOOTBALL}-odds-edit-component`}>
				{props.displayOddsLabel && (
					<Row className='mb-1 mt-4'>
						<Col>
							<h5>{props.t('odds_data')}</h5>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && props.selectMainBookmaker && (
					<Row>
						<Col>
							<FormGroup>
								<BookmakerSelectComponent
									t={props.t}
									isValid={props.isValid}
									isMulti={false}
									showAllBookmakers={false}
									onSelect={onMainBookmakerChange}
									bookmakerSelectionEnabled={bookmakerSelectionEnabled}
									eventId={oddsWidgetModel.match && oddsWidgetModel.match.id ? oddsWidgetModel.match.id : null}
									value={oddsWidgetModel.mainBookmaker}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && oddsWidgetModel.mainBookmaker && oddsWidgetModel.mainBookmaker.id && (
					<Row>
						<Col>
							<FormGroup>
								<MarketSelect
									t={props.t}
									value={oddsWidgetModel.market ? oddsWidgetModel.market.name : marketOptionsV2(props.t)[0].value}
									onMarketSelect={onMarketChange}
									limitTo1x2={false}
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MARKET_SELECT}`}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				{props.displayHeader && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key='display-header' check className='radio'>
								<Input
									key='display-header-input'
									className='form-check-input'
									type='checkbox'
									id={`display-header-checkbox-${props.block.id}`}
									name='radios'
									onChange={onDisplayHeaderChange}
									checked={oddsWidgetModel.displayMatchHeader}
								/>
								<Label key='display-header-label' check className='form-check-label' for={`display-header-checkbox-${props.block.id}`}>
									{props.t('display_header')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{props.displayMarketSelection && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key='can-select-markets' check className='radio'>
								<Input
									key='can-select-markets-input'
									className='form-check-input'
									type='checkbox'
									id={`can-select-markets-checkbox-${props.block.id}`}
									name='radios'
									onChange={onCanSelectMarketsChange}
									checked={oddsWidgetModel.canSelectMarkets}
								/>
								<Label key='can-select-markets-label' check className='form-check-label' for={`can-select-markets-checkbox-${props.block.id}`}>
									{props.t('display_market_selection')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}

				<div className='mb-3'>
					<ErrorHandler
						arePropsValid={props.isValid}
						isValueSelected={!oddsWidgetModel.mainBookmaker}
						t={props.t}
						errorMessage='select_bookmaker'
					/>
				</div>
			</div>
		</>
	);
};
