import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import BookmakerModelV2 from '../../../../partials/bookmaker-select/models/bookmaker.model';
import MatchModelV2 from '../../../football-single-event/models/MatchModelV2.model';
import MarketModel from './market.model';
import MarketValueTypeModel from './market-value-type.model';
import SportOddsBookmakerModel from '../../../../../../../../models/sport-odds/sport-odds-bookmaker.model';

@jsonObject
export default class OddsV2Model {
	@jsonMember(MatchModelV2)
	public match: MatchModelV2 = new MatchModelV2();

	@jsonMember(SportOddsBookmakerModel)
	public mainBookmaker: SportOddsBookmakerModel | null = null;

	@jsonMember(MarketModel)
	public market: MarketModel = new MarketModel();

	@jsonMember(MarketValueTypeModel)
	public marketValueType: MarketValueTypeModel = new MarketValueTypeModel();

	@jsonArrayMember(SportOddsBookmakerModel)
	public bookmakers: SportOddsBookmakerModel[] = [];

	@jsonMember(Boolean)
	public displayMatchHeader: boolean = true;

	@jsonMember(Boolean)
	public canSelectMarkets: boolean = true;
}
