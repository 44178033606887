import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import TeamSelect from '../../../../partials/team-select/team-select.component';
import TeamModel from '../../../../partials/team-select/models/team.model';
import {
	activeTabOptions,
	checkIfAwayShirtColorExists,
	checkIfMatchContainsColors,
	extractMatchColors,
	extractTeamIDsFromMatch,
	generateCorrectError,
	getActiveTab,
	remapMatchFromOption,
	returnErrorForColors,
} from '../helpers/match-widget.helper';
import Select from 'react-select';
import MatchesContainer from '../../../../partials/matches/matches-container';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import { Properties, State } from './properties/match-widget-edit.properties';
import MatchModel from '../../../../partials/match-select/models/match.model';
import MatchWidgetModel from '../models/match-widget.model';
import OddsWidgetEdit from '../../odds/components/odds-widget-edit.component';
import OddsWidgetModel from '../../odds/models/odds-widget.model';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { prefillMatchFromRelated } from '../../helpers/widgets-prefill.helpers';
import ColorPickerComponent from './color-picker.component';
import './styles/color-picker.scss';
import ThreeLetterCodeTypeSelect from '../../../../partials/three-letter-code-type-select/three-letter-code-type-select';
import HttpService from '../../../../../../../services/rest/HttpService';

export default class MatchWidgetEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		const matchContentData =
			props.contentData && props.contentData.related && props.contentData.related.matches
				? prefillMatchFromRelated(props.contentData.related.matches)
				: MatchModel.builder().withHomeTeam(TeamModel.builder().build()).build();
		this.state = {
			widgetModel:
				props.block && props.block.data.preview && props.block.data.preview.match
					? MatchWidgetModel.builder(props.block.data.preview.match).build()
					: MatchWidgetModel.builder()
							.withTeams(matchContentData.homeTeam.id ? [matchContentData.homeTeam] : [])
							.withMatch(prefillMatchFromRelated(props.contentData && props.contentData.related && props.contentData.related.matches))
							.withDisplayOdds(!!(matchContentData && matchContentData.id && matchContentData.type && matchContentData.type !== 'finished'))
							.withHideEvents(false)
							.withActiveTab(getActiveTab('overview', props.t)[0].value)
							.withHomeTeamColor(null)
							.withAwayTeamColor(null)
							.withThreeLetterCodeType('')
							.build(),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsWidgetModel.builder(props.block.data.preview.odds).build()
					: OddsWidgetModel.builder().withBookmakers([]).withCanSelectMarkets(false).withDisplayHeader(false).build(),
			isValid: true,
			displayHomeTeamColorPicker: false,
			displayAwayTeamColorPicker: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.state.widgetModel.teams.length < 1 &&
			this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
			!this.state.widgetModel.displayOdds
		) {
			const emptyOddsModel = OddsWidgetModel.builder().withBookmakers(null).withCanSelectMarkets(false).withDisplayHeader(false).build();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	onSave = (index: number) => {
		const data = { matchModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });
		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsWidgetModel.builder(this.state.oddsModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.oddsModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { match: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: MatchWidgetModel) => this.setState({ ...this.state, widgetModel: model });

	onTeamChange = (teams: TeamModel[]) => {
		this.updateWidgetModelState(
			MatchWidgetModel.builder(this.state.widgetModel).withTeams(teams).withMatch(MatchModel.builder().build()).withDisplayOdds(false).build(),
		);
	};

	onOddsWidgetChange = (oddsModel: OddsWidgetModel) => this.setState({ ...this.state, oddsModel });

	onMatchChange = (match: MatchModel) => {
		const widgetModel = MatchWidgetModel.builder(this.state.widgetModel)
			.withMatch(remapMatchFromOption(match))
			.withDisplayOdds(match && match.type === 'finished' ? false : true)
			.build();
		const oddsModel = OddsWidgetModel.builder().withBookmakers(null).withCanSelectMarkets(false).withDisplayHeader(false).build();

		this.setState({
			...this.state,
			isValid: true,
			widgetModel,
			oddsModel,
		});

		HttpService.getMatchColors(match.id).then((matchData: object) => {
			let colors = extractMatchColors(matchData);
			if (colors.homeTeamColor === colors.awayTeamColor && colors.homeTeamColor !== null && colors.awayTeamColor !== null) {
				//if both teams have colors and they are the same color
				let teamIDs = extractTeamIDsFromMatch(matchData);
				this.getAwayTeamsColor(teamIDs.awayTeam).then((result) => {
					this.setMatchBlockyColors(colors.homeTeamColor, result);
				});
			} else if (checkIfMatchContainsColors(matchData)) {
				//check if at least one of the matches has a color
				this.setMatchBlockyColors(colors.homeTeamColor, colors.awayTeamColor);
			} else {
				//none of the matches contains a color
				this.setMatchBlockyColors(null, null);
			}
		});
	};

	getAwayTeamsColor(id: string) {
		// returns Away Team's away shirt color. If none exists, returns null
		// let awayShirtColor;
		return HttpService.getTeamColors(id).then((response: any) => {
			return checkIfAwayShirtColorExists(response) ? response.data.shirt_colors[1].color_code : null;
		});
	}

	setMatchBlockyColors = (homeTeamColor: string | null, awayTeamColor: string | null) => {
		this.updateWidgetModelState(
			MatchWidgetModel.builder(this.state.widgetModel).withHomeTeamColor(homeTeamColor).withAwayTeamColor(awayTeamColor).build(),
		);
	};

	onActiveTabChange = (tab: string) => {
		this.updateWidgetModelState(MatchWidgetModel.builder(this.state.widgetModel).withActiveTab(tab).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(
			MatchWidgetModel.builder(this.state.widgetModel)
				.withDisplayOdds(e.target.checked)
				.withActiveTab(e.target.checked ? this.state.widgetModel.activeTab : '')
				.build(),
		);
	};

	onHideEventsChange = (e: any) => {
		this.updateWidgetModelState(MatchWidgetModel.builder(this.state.widgetModel).withHideEvents(e.target.checked).build());
	};

	handleHomeTeamChangeColor = (color: any) => {
		this.updateWidgetModelState(MatchWidgetModel.builder(this.state.widgetModel).withHomeTeamColor(color.hex).build());
	};

	handleAwayTeamChangeColor = (color: any) => {
		this.updateWidgetModelState(MatchWidgetModel.builder(this.state.widgetModel).withAwayTeamColor(color.hex).build());
	};

	toggleHomeTeamColorPicker = (toggle: boolean) => {
		this.setState({ ...this.state, displayHomeTeamColorPicker: toggle });
	};

	toggleAwayTeamColorPicker = (toggle: boolean) => {
		this.setState({ ...this.state, displayAwayTeamColorPicker: toggle });
	};

	onThreeLetterCodeTypeChange = (value: any) => {
		this.updateWidgetModelState(MatchWidgetModel.builder(this.state.widgetModel).withThreeLetterCodeType(value).build());
	};

	render() {
		const { widgetModel, oddsModel, displayHomeTeamColorPicker, displayAwayTeamColorPicker } = this.state;
		const options = activeTabOptions(this.props.t);
		const tabOptions = widgetModel.displayOdds ? options : options.filter((tab) => tab.value !== 'odds');
		const homeTeamColor = widgetModel.homeTeamColor;
		const awayTeamColor = widgetModel.awayTeamColor;
		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								inputId='match-team-select'
								isMulti={true}
								t={this.props.t}
								value={widgetModel.teams ? widgetModel.teams : []}
								onSelect={(team: TeamModel[]) => this.onTeamChange(team)}
								isValid={this.state.isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<MatchesContainer
								t={this.props.t}
								teamIds={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? [widgetModel.teams.filter((team) => team).map((team: any) => team.id)]
										: []
								}
								isMulti={false}
								maxNotStartedMatches={20}
								maxFinishedMatches={20}
								maxInterruptedMatches={20}
								teamNames={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? widgetModel.teams.filter((team) => team).map((team: TeamModel) => team.name)
										: ['']
								}
								selectedMatchId={widgetModel && widgetModel.match ? widgetModel.match.id : ''}
								onSelect={this.onMatchChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<Label>{this.props.t('default_tab')}:</Label>
						<Select
							options={tabOptions}
							value={getActiveTab(widgetModel.activeTab, this.props.t)}
							noOptionsMessage={(inputValue) => inputValue && this.props.t('no_options')}
							onChange={(selection: any) => {
								this.onActiveTabChange(selection.value);
							}}
						/>
					</Col>
				</Row>

				{widgetModel.teams && widgetModel.teams.length > 0 && (
					<ThreeLetterCodeTypeSelect
						t={this.props.t}
						isClearable={true}
						onThreeLetterCodeTypeChange={this.onThreeLetterCodeTypeChange}
						selectedThreeLetterCodeType={widgetModel.threeLetterCodeType}
					/>
				)}

				{widgetModel.match &&
					widgetModel.match.homeTeam &&
					widgetModel.match.homeTeam.name &&
					widgetModel.match.awayTeam &&
					widgetModel.match.awayTeam.name && (
						<Row className='mb-2 mt-2'>
							<Col>
								<div className='d-flex align-items-center'>
									<Label className='mr-2 mb-0'>
										{widgetModel.match.homeTeam.name} {this.props.t('color')}:
									</Label>
									<div onClick={() => this.toggleHomeTeamColorPicker(true)} className='swatch teams-color-picker-box'>
										<div className={'color ' + (!homeTeamColor ? 'no-color-selected-bg' : '')} style={{ background: homeTeamColor }} />
									</div>
									{displayHomeTeamColorPicker && (
										<div className='popover'>
											<div className='cover' onClick={() => this.toggleHomeTeamColorPicker(false)} />
											<ColorPickerComponent
												color={'transparent'}
												handleChangeColor={this.handleHomeTeamChangeColor}
												toggleTeamColorPicker={this.toggleHomeTeamColorPicker}
											/>
										</div>
									)}
								</div>
								{!homeTeamColor && (awayTeamColor || !awayTeamColor) && returnErrorForColors(this.state.isValid, homeTeamColor, awayTeamColor) && (
									<div className='mt-1'>
										<ErrorHandler t={this.props.t} errorMessage={generateCorrectError(homeTeamColor, awayTeamColor)} />
									</div>
								)}
							</Col>
							<Col>
								<div className='d-flex align-items-center'>
									<Label className='mr-2 mb-0'>
										{widgetModel.match.awayTeam.name} {this.props.t('color')}:
									</Label>
									<div onClick={() => this.toggleAwayTeamColorPicker(true)} className='swatch teams-color-picker-box'>
										<div className={'color ' + (!awayTeamColor ? 'no-color-selected-bg' : '')} style={{ background: awayTeamColor }} />
									</div>
									{displayAwayTeamColorPicker && (
										<div className='popover'>
											<div className='cover' onClick={() => this.toggleAwayTeamColorPicker(false)} />
											<ColorPickerComponent
												color={'transparent'}
												handleChangeColor={this.handleAwayTeamChangeColor}
												toggleTeamColorPicker={this.toggleAwayTeamColorPicker}
											/>
										</div>
									)}
								</div>
								{(homeTeamColor || !homeTeamColor) && !awayTeamColor && returnErrorForColors(this.state.isValid, homeTeamColor, awayTeamColor) && (
									<div className='mt-1'>
										<ErrorHandler t={this.props.t} errorMessage={generateCorrectError(homeTeamColor, awayTeamColor)} />
									</div>
								)}
							</Col>
						</Row>
					)}
				{homeTeamColor && awayTeamColor && returnErrorForColors(this.state.isValid, homeTeamColor, awayTeamColor) && widgetModel.match.id && (
					<div className='mb-3'>
						<ErrorHandler t={this.props.t} errorMessage={generateCorrectError(homeTeamColor, awayTeamColor)} />
					</div>
				)}
				<hr />
				{widgetModel && widgetModel.match && widgetModel.match.type !== 'finished' && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-odds-markets`} check className='radio'>
								<Input
									key={`display-odds-markets-input`}
									className='form-check-input'
									type='checkbox'
									id={`display-odds-markets-checkbox-${this.props.block.id}`}
									name='radios'
									onChange={this.onDisplayOddsChange}
									checked={widgetModel.displayOdds}
								/>
								<Label
									key={`display-odds-markets-label`}
									check
									className='form-check-label'
									for={`display-odds-markets-checkbox-${this.props.block.id}`}
								>
									{this.props.t('display_odds')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{widgetModel && widgetModel.match && widgetModel.match.type !== 'finished' && widgetModel.displayOdds && (
					<OddsWidgetEdit
						contentData={this.props.contentData}
						widgetType='matchWidget'
						block={this.props.block}
						isStandAlone={false}
						useTeamSelect={false}
						useMatchSelect={false}
						t={this.props.t}
						match={widgetModel && widgetModel.match ? widgetModel.match : oddsModel.match}
						team={widgetModel && widgetModel.teams ? widgetModel.teams[0] : oddsModel.team}
						onOddsWidgetChange={this.onOddsWidgetChange}
						autoSelectFirstMatch={true}
						displayOddsLabel={true}
						selectMainBookmaker
						displayHeader={false}
						isValid={this.state.isValid}
						currentOdds={oddsModel}
					/>
				)}
				<Row className='mb-2'>
					<Col>
						<FormGroup key={`display-hide-events`} check className='radio-hide-events'>
							<Input
								key={`display-hide-events-input`}
								className='form-check-input-hide-events'
								type='checkbox'
								id={`display-hide-events-checkbox-${this.props.block.id}`}
								name='radios'
								onChange={this.onHideEventsChange}
								checked={widgetModel.hideEvents}
							/>
							<Label
								key={`display-hide-events-label`}
								check
								className='form-check-label-hide-events'
								for={`display-hide-events-checkbox-${this.props.block.id}`}
							>
								{this.props.t('hide_events')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				{!this.state.isValid && widgetModel.match && !widgetModel.match.id && (
					<div className='mb-3'>
						<ErrorHandler t={this.props.t} errorMessage='no_matches_selected' />
					</div>
				)}
			</>
		);
	}
}
