import { featuresService } from '../../../../../App';
import { FeatureTypes } from '../../../../../services/feature-service/features.enum';
import { WidgetTypesV2 } from '../../constants/block.types';
import TennisPlayoffModel from '../widgets-v2/tennis-playoff/models/tennis-playoff.model';
import TennisRankingModel from '../widgets-v2/tennis-ranking/models/tennis-ranking.model';
import TennisAthleteProgrammeModel from '../widgets-v2/tennis-athlete-programme/models/tennis-athlete-programme.model';
import TennisTournamentProgrammeModel from '../widgets-v2/tennis-tournament-programme/models/tennis-tournament-programme.model';
import OddsModelV2 from '../widgets-v2/odds/models/odds-widget.model';

export default class ValidationV2 {
	public validate = (widgetType: string, data: any, subType: string) => {
		switch (widgetType) {
			case WidgetTypesV2.footballStandings:
				return this.footballStandings(data);
			case WidgetTypesV2.footballLivescore:
				return this.footballLivescore(data);
			case WidgetTypesV2.footballTeamProgramme:
				return this.footballTeamProgramme(data);
			case WidgetTypesV2.footballKnockout:
				return this.footballKnockout(data);
			case WidgetTypesV2.footballPlayer:
				return this.footballPlayer(data);
			case WidgetTypesV2.footballSingleEvent:
				return this.footballSingleEvent(data);
			case WidgetTypesV2.footballTeam:
				return this.footballTeam(data);
			case WidgetTypesV2.footballTeamSquad:
				return this.footballTeamSquad(data);
			case WidgetTypesV2.footballTournamentProgramme:
				return this.footballTournamentProgramme(data);
			case WidgetTypesV2.footballPlayerH2H:
				return this.footballPlayerH2H(data);
			case WidgetTypesV2.footballTeamH2H:
				if (subType === WidgetTypesV2.footballTeamSeasonStats) {
					return this.footballTeamH2H(data);
				} else {
					return this.widgetFootballMatchStats(data);
				}
			case WidgetTypesV2.footballMatchesH2H:
				return this.footballMatchesH2H(data);
			case WidgetTypesV2.footballTeamForm:
				return this.footballTeamForm(data);
			case WidgetTypesV2.footballTopScorers:
				return this.footballTopScorers(data);
			case WidgetTypesV2.footballMostDecoratedPlayers:
				return this.footballMostDecoratedPlayers(data);
			case WidgetTypesV2.footballLineups:
				return this.footballLineups(data);
			case WidgetTypesV2.basketballSingleEvent:
				return this.widgetBasketballSingleEvent(data);
			case WidgetTypesV2.basketballLivescore:
				return this.widgetBasketballLivescore(data);
			case WidgetTypesV2.basketballStandings:
				return this.widgetBasketballStandings(data);
			case WidgetTypesV2.basketballTournamentProgramme:
				return this.widgetBasketballTournamentProgramme(data);
			case WidgetTypesV2.basketballTeamProgramme:
				return this.widgetBasketballTeamProgramme(data);
			case WidgetTypesV2.tennisSingleEvent:
				return this.widgetTennisSingleEvent(data);
			case WidgetTypesV2.tennisLivescore:
				return this.widgetTennisLivescore(data);
			case WidgetTypesV2.tennisRanking:
				return this.widgetTennisRanking(data.widgetModel);
			case WidgetTypesV2.tennisPlayoff:
				return this.widgetTennisPlayoff(data.widgetModel);
			case WidgetTypesV2.tennisAthleteProgramme:
				return this.widgetTennisAthleteProgramme(data.widgetModel);
			case WidgetTypesV2.tennisTournamentProgramme:
				return this.widgetTennisTournamentProgramme(data.widgetModel);
			case WidgetTypesV2.footballOdds:
				return this.footballOdds(data.widgetModel);
			default:
				return false;
		}
	};

	private bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

	private checkIfBookmakersAttributeIsValid = (widgetModel: any) => {
		return widgetModel.displayOdds && this.bookmakerSelectionEnabled && widgetModel.bookmakers && widgetModel.bookmakers.length > 0;
	};

	private checkIfMainBookmakerAttributeIsValid = (widgetModel: any, odds: any) => {
		return widgetModel && widgetModel.displayOdds && this.bookmakerSelectionEnabled && odds.mainBookmaker && odds.mainBookmaker.id;
	};

	private checkIfDisplayOddsIsChecked = (widgetModel: any) => {
		return widgetModel.displayOdds;
	};

	private footballStandings = (widgetModel: any) => {
		return !!(widgetModel && widgetModel.widgetModel.tournament && widgetModel.widgetModel.tournament.id);
	};

	private footballLivescore = (data: any) => {
		const { widgetModel } = data;

		const isValidDate = !!(widgetModel && widgetModel.date && widgetModel.date !== '');
		const areOddsValid = widgetModel.displayOdds ? this.checkIfBookmakersAttributeIsValid(widgetModel) : true;

		return isValidDate && areOddsValid;
	};

	private footballTeamProgramme = (data: any) => {
		const { widgetModel } = data;
		const isValidSeason = !!(widgetModel && widgetModel.season && widgetModel.season.id);
		const isValidTeam = !!(widgetModel && widgetModel.team && widgetModel.team.id);

		return isValidSeason && isValidTeam;
	};

	private footballKnockout = (data: any) => {
		const { widgetModel } = data;
		const isValidTournament = !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id);
		const isValidSeason = !!(widgetModel && widgetModel.season && widgetModel.season.id);
		const isValidStage = !!(widgetModel && widgetModel.stage && widgetModel.stage.id);

		return isValidTournament && isValidSeason && isValidStage;
	};

	private footballSingleEvent = (data: any) => {
		const { widgetModel } = data;
		const { oddsModel } = data;

		const isValidGame = !!(widgetModel && widgetModel.match && widgetModel.match.id);
		const areBookmakersValid = this.checkIfMainBookmakerAttributeIsValid(widgetModel, oddsModel);
		const eventId = !!(oddsModel && oddsModel.match && oddsModel.match.id && areBookmakersValid);
		const areOddsValid = widgetModel && this.checkIfDisplayOddsIsChecked(widgetModel) ? eventId : true;

		return isValidGame && areOddsValid;
	};

	private footballPlayer = (data: any) => {
		const { widgetModel } = data;
		const { oddsModel } = data;

		const isValidPlayer = !!(widgetModel && widgetModel.player && widgetModel.player.id);
		const isValidTournamentSeason = !!(widgetModel && widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId);
		const areBookmakersValid = this.checkIfMainBookmakerAttributeIsValid(widgetModel, oddsModel);
		const eventId = !!(oddsModel && oddsModel.match && oddsModel.match.id && areBookmakersValid);
		const areOddsValid = widgetModel && this.checkIfDisplayOddsIsChecked(widgetModel) ? eventId : true;

		return isValidPlayer && isValidTournamentSeason && areOddsValid;
	};

	private footballTeam = (data: any) => {
		const { widgetModel } = data;
		const { oddsModel } = data;

		const isValidTeam = !!(widgetModel && widgetModel.team && widgetModel.team.id);
		const isValidSeason = !!(widgetModel && widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId);

		const areBookmakersValid = this.checkIfMainBookmakerAttributeIsValid(widgetModel, oddsModel);
		const eventId = !!(oddsModel && oddsModel.match && oddsModel.match.id && areBookmakersValid);
		const areOddsValid = widgetModel && this.checkIfDisplayOddsIsChecked(widgetModel) ? eventId : true;

		return isValidTeam && isValidSeason && areOddsValid;
	};

	private footballTeamSquad = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.team && widgetModel.team.id);
	};

	private footballTournamentProgramme = (data: any) => {
		const { widgetModel } = data;

		const areOddsValid = widgetModel.displayOdds ? this.checkIfBookmakersAttributeIsValid(widgetModel) : true;
		return !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id) && areOddsValid;
	};

	private footballPlayerH2H = (data: any) => {
		const { widgetModel } = data;

		return !!(
			widgetModel &&
			widgetModel.player1TournamentSeason &&
			widgetModel.player1TournamentSeason.seasonId &&
			widgetModel.player2TournamentSeason &&
			widgetModel.player2TournamentSeason.seasonId
		);
	};

	private footballTeamH2H = (data: any) => {
		const { widgetModel } = data;

		return !!(
			widgetModel &&
			widgetModel.team1TournamentSeason &&
			widgetModel.team1TournamentSeason.seasonId &&
			widgetModel.team2TournamentSeason &&
			widgetModel.team2TournamentSeason.seasonId
		);
	};

	private footballMatchesH2H = (data: any) => {
		const { widgetModel } = data;
		const isValidLimit = !!(widgetModel && widgetModel.limit && widgetModel.limit !== '' && Number(widgetModel.limit) > 0);

		return !!(widgetModel && widgetModel.team1 && widgetModel.team1.id && widgetModel.team2 && widgetModel.team2.id) && isValidLimit;
	};

	private widgetFootballMatchStats = (data: any) => {
		const { widgetModel } = data;
		const { oddsModel } = data;

		const areBookmakersValid = this.checkIfMainBookmakerAttributeIsValid(widgetModel, oddsModel);
		const eventId = !!(oddsModel && oddsModel.match && oddsModel.match.id && areBookmakersValid);
		const areOddsValid = widgetModel && this.checkIfDisplayOddsIsChecked(widgetModel) ? eventId : true;

		return !!(
			widgetModel &&
			widgetModel.team1TournamentSeason &&
			widgetModel.team1TournamentSeason.seasonId &&
			widgetModel.team2TournamentSeason &&
			widgetModel.team2TournamentSeason.seasonId &&
			areOddsValid
		);
	};

	private footballTeamForm = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.team && widgetModel.team.id);
	};

	private footballTopScorers = (data: any) => {
		const { widgetModel } = data;

		return !!(widgetModel && widgetModel.tournament && widgetModel.tournament.id);
	};

	private footballMostDecoratedPlayers = (data: any) => {
		const { widgetModel } = data;
		const isValidStartFrom =
			widgetModel && widgetModel.startFrom ? +widgetModel.startFrom > 0 && +widgetModel.startFrom <= widgetModel.players.length : true;
		const isValidTournament = widgetModel && widgetModel.tournament && widgetModel.tournament.id;
		const isValidSeason = widgetModel && widgetModel.season && widgetModel.season.id;
		return isValidTournament && isValidSeason && isValidStartFrom;
	};

	private footballLineups = (data: any) => {
		const { widgetModel } = data;

		const isValidMatch = !!(widgetModel && widgetModel.match && widgetModel.match.id);
		const areColorsSelected =
			widgetModel.homeTeamColor &&
			widgetModel.awayTeamColor &&
			true &&
			true &&
			widgetModel.homeTeamColor.length > 0 &&
			widgetModel.awayTeamColor.length > 0;
		const areColorsDifferent = widgetModel.homeTeamColor !== widgetModel.awayTeamColor;
		return isValidMatch && areColorsSelected && areColorsDifferent;
	};

	private widgetBasketballSingleEvent = (data: any) => {
		const isValidGame = !!(data.gameModel && data.gameModel.game && data.gameModel.game.id);
		const areBookmakersValid = this.bookmakerSelectionEnabled ? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0 : true;
		const eventId = !!(data.oddsModel && data.oddsModel.game && data.oddsModel.game.id && areBookmakersValid);
		const areOddsValid = data.gameModel.displayOdds ? eventId : true;
		return isValidGame && areOddsValid;
	};

	private widgetBasketballLivescore = (data: any) => {
		const isValidDate = !!(data.widgetModel && data.widgetModel.date && data.widgetModel.date !== '');
		const areBookmakersValid = this.bookmakerSelectionEnabled ? data.widgetModel.bookmakers && data.widgetModel.bookmakers.length > 0 : true;

		const areOddsValid = data.widgetModel.displayOdds ? areBookmakersValid : true;
		return isValidDate && areOddsValid;
	};

	private widgetBasketballStandings = (data: any) => {
		return !!(data.widgetModel && data.widgetModel.competition && data.widgetModel.competition.id);
	};

	private widgetBasketballTournamentProgramme = (data: any) => {
		const isValidCompetition = !!(data.widgetModel && data.widgetModel.competition && data.widgetModel.competition.id !== '');
		const areBookmakersValid = this.bookmakerSelectionEnabled ? data.widgetModel.bookmakers && data.widgetModel.bookmakers.length > 0 : true;

		const areOddsValid = data.widgetModel.displayOdds ? areBookmakersValid : true;
		return isValidCompetition && areOddsValid;
	};

	private widgetBasketballTeamProgramme = (data: any) => {
		const isValidTeam = !!(data.widgetModel && data.widgetModel.team && data.widgetModel.team.id !== '');
		const areBookmakersValid = this.bookmakerSelectionEnabled ? data.widgetModel.bookmakers && data.widgetModel.bookmakers.length > 0 : true;

		const areOddsValid = data.widgetModel.displayOdds ? areBookmakersValid : true;
		return isValidTeam && areOddsValid;
	};

	private widgetTennisLivescore = (data: any) => {
		const isValidDate = !!(data.widgetModel && data.widgetModel.date && data.widgetModel.date !== '');
		const areBookmakersValid = this.bookmakerSelectionEnabled ? data.widgetModel.bookmakers && data.widgetModel.bookmakers.length > 0 : true;

		const areOddsValid = data.widgetModel.displayOdds ? areBookmakersValid : true;
		return isValidDate && areOddsValid;
	};

	private widgetTennisSingleEvent = (data: any) => {
		const isValidMatch = !!(data.matchModel && data.matchModel.match && data.matchModel.match.id);
		const areBookmakersValid = this.bookmakerSelectionEnabled ? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0 : true;
		const isValidEventId = !!(data.oddsModel && data.oddsModel.game && data.oddsModel.game.id && areBookmakersValid);
		const areOddsValid = data.matchModel.displayOdds ? isValidEventId : true;
		return isValidMatch && areOddsValid;
	};

	private widgetTennisRanking = (widgetModel: TennisRankingModel) => {
		const isValidLimit = !!(widgetModel && widgetModel.limit && widgetModel.limit !== '' && Number(widgetModel.limit) > 0);
		const isValidOffset = !!(widgetModel && widgetModel.offset && widgetModel.offset !== '');
		const isValidStandingType = !!(widgetModel && widgetModel.availableStandingType && widgetModel.availableStandingType.id);

		return isValidLimit && isValidOffset && isValidStandingType;
	};

	private widgetTennisPlayoff = (widgetModel: TennisPlayoffModel) => {
		return !!(widgetModel && widgetModel.competition && widgetModel.competition.id);
	};

	private widgetTennisAthleteProgramme = (widgetModel: TennisAthleteProgrammeModel) => {
		const isValidAthlete = !!(widgetModel && widgetModel.athlete && widgetModel.athlete.id);
		const isValidCompetition = !!(widgetModel && widgetModel.competition && widgetModel.competition.id);

		const areBookmakersValid = this.bookmakerSelectionEnabled ? !!(widgetModel.bookmakers && widgetModel.bookmakers.length > 0) : true;

		const areOddsValid = widgetModel.displayOdds ? areBookmakersValid : true;

		return isValidAthlete && isValidCompetition && areOddsValid;
	};

	private widgetTennisTournamentProgramme = (widgetModel: TennisTournamentProgrammeModel) => {
		const isValidCompetition = !!(widgetModel && widgetModel.competition && widgetModel.competition.id);
		const areBookmakersValid = this.bookmakerSelectionEnabled ? !!(widgetModel.bookmakers && widgetModel.bookmakers.length > 0) : true;

		const areOddsValid = widgetModel.displayOdds ? areBookmakersValid : true;

		return isValidCompetition && areOddsValid;
	};

	private footballOdds = (widgetModel: OddsModelV2) => {
		return !!(widgetModel.match && widgetModel.match.id);
	};
}
