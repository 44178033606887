import { OddsJsonV2 } from './odds-widget.json';
import OddsModelV2 from './odds-widget.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import MarketModel from './v2/market.model';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import MarketValueTypeModel from './v2/market-value-type.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class OddsBuilderV2 {
	readonly json: OddsJsonV2;

	constructor(odds?: OddsModelV2 | OddsJsonV2) {
		if (odds && odds instanceof OddsModelV2) {
			this.json = odds.toJson();
		} else if (odds) {
			this.json = odds;
		} else {
			this.json = {} as OddsJsonV2;
		}
	}

	withTeam(team: TeamModel): OddsBuilderV2 {
		this.json.team = team;

		return this;
	}

	withMatch(match: MatchModelV2): OddsBuilderV2 {
		this.json.match = match;

		return this;
	}

	withMainBookmaker(mainBookmaker: SportOddsBookmakerModel | null): OddsBuilderV2 {
		this.json.mainBookmaker = mainBookmaker;

		return this;
	}

	withMarket(market: MarketModel): OddsBuilderV2 {
		this.json.market = market;

		return this;
	}

	withMarketValueType(marketValue: MarketValueTypeModel): OddsBuilderV2 {
		this.json.marketValueType = marketValue;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): OddsBuilderV2 {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): OddsBuilderV2 {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): OddsModelV2 {
		return OddsModelV2.fromJson(this.json);
	}
}
