import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import MatchModelV2 from '../../../widgets-v2/football-single-event/models/MatchModelV2.model';

export const mapResponseToModel = (data: any) => {
	const model = new EntitySeasonsSelectModel();

	if (data.season) {
		model.seasonId = data.season.id;
		model.seasonName = data.season.name;
		model.tournamentId = data.tournament.id;
		model.tournamentName = data.tournament.name;
	} else {
		model.seasonId = data.id;
		model.seasonName = data.name;
		model.tournamentId = data.tournament.id;
		model.tournamentName = data.tournament.name;
	}

	return model;
};

export const mapMatchTournamentSeasonStageToModel = (match: MatchModelV2) => {
	const model = new EntitySeasonsSelectModel();
	if (match && match.season) {
		model.seasonId = match.season.id;
		model.seasonName = match.season.name;
		model.tournamentId = match.season.tournament ? match.season.tournament.id : '';
		model.tournamentName = match.season.tournament ? match.season.tournament.name : '';
	}

	return model;
};

export const mapResponseListToPlayerSeasonsModel = (response: any) => {
	if (response && response.length > 0) {
		return response.map((item: any) => mapResponseToModel(item));
	} else return [];
};

export const playerSeasonToOption = (season: EntitySeasonsSelectModel) => {
	if (season) {
		return {
			value: season.seasonId,
			label: season.tournamentName + '-' + season.seasonName,
			data: season,
			type: 'player-season',
		};
	} else {
		return {};
	}
};
