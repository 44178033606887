import React, { FunctionComponent } from 'react';
import { Col, Label, Row } from 'reactstrap';
import LiveBlogCollaboratorsModel from '../../../models/live-blog-collaborators.model';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';

type Properties = {
	collaborator: LiveBlogCollaboratorsModel;
	handleDeleteRow: Function;
	setToggle: Function;
	t: any;
	index: number;
	setSelectedCollaboratorForEdit: (collaborator: LiveBlogCollaboratorsModel, newCollaborator: boolean) => void;
};

export const CollaboratorInfoCard: FunctionComponent<Properties> = ({
	collaborator,
	t,
	handleDeleteRow,
	index,
	setToggle,
	setSelectedCollaboratorForEdit,
}) => {
	const placeholder = assetsPlaceholder.player;
	const avatarImage =
		collaborator.author && collaborator.author.avatarImage
			? collaborator.author.avatarImage
			: collaborator.author.avatar_image
			? collaborator.author.avatar_image
			: placeholder;

	return (
		<div className='card-body'>
			<Row>
				<Col>
					{collaborator.author.id && (
						<div id={`${collaborator.author.name}-${index + 1}`}>
							<Row className='mb-1'>
								<Label className='mb-1'>
									<strong>{t('collaborator')}</strong>
								</Label>
							</Row>

							<img
								width='20'
								height='20'
								className='author-image'
								src={avatarImage}
								onError={(i: any) => (i.target.src = assetsPlaceholder.player)}
								alt={collaborator.author.bio ? collaborator.author.bio : ''}
							/>

							<span className='ml-0'>{collaborator.author.name} </span>
							<Row className='mt-2'>
								<Label className='mb-1'>
									<strong>{t('additional_info')}</strong>
								</Label>
							</Row>
							{collaborator.additional_info && collaborator.additional_info.description && collaborator.additional_info.description.length > 0 && (
								<span>{collaborator.additional_info.description} </span>
							)}
						</div>
					)}
				</Col>
				<Col md={1}>
					<div
						className='cursor-pointer'
						onClick={() => {
							setSelectedCollaboratorForEdit(collaborator, false);
							setToggle(true);
						}}
					>
						<i className='fa fa-pen p-1' />
					</div>
				</Col>
				<Col md={1}>
					<div className='cursor-pointer' onClick={() => handleDeleteRow(collaborator.author.id)}>
						<i className='fa fa-trash-can p-1' />
					</div>
				</Col>
			</Row>
		</div>
	);
};
