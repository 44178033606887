import * as React from 'react';
import { Properties } from './properties/MainMediaProps';
import MainMedia from '../../../../models/mainmedia/MainMedia';
import MainContentMediaContainer from './subcomponents/media-content/MainContentMediaContainer';
import MainImageMedia from './subcomponents/media-main-image/MainImageMedia';
import Project from '../../../../models/project/Project';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import MediaVideoEmbed from './subcomponents/media-embed/MediaVideoEmbed';
import { gallerySelectOptions, selectOptions } from './helpers/MainMediaListSelectOptions';
import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { ContentTypes } from '../../../../constants/content-types';

export default class MainMediaList extends React.Component<Properties> {
	onMainMediaChange = (mainMedia: MainMedia) => {
		this.props.onMediaUpdate(mainMedia);
	};

	onMediaRemove = (type: string, subType: string) => {
		this.props.onRemove(type, subType);
	};

	onMainMediaAdd = (selection: any) => {
		this.props.onMediaAdd(
			MainMedia.builder()
				.withDescription('')
				.withResourceSubtype(selection.subType)
				.withResourceType(selection.type)
				.withData(selection.data)
				.build(),
		);
	};

	displayMainMediaList(mainMedia: MainMedia[], project: Project, t: any) {
		if (mainMedia) {
			return mainMedia.map((item: MainMedia, index) => {
				if (item.resourceType === 'video' && item.resourceSubtype === null) {
					return (
						<MainContentMediaContainer
							onRemove={this.onMediaRemove}
							value={item}
							index={index}
							key={'video-type-media-key'}
							onContentSelect={this.onMainMediaChange}
							t={t}
							project={project}
							contentType={'video'}
						/>
					);
				}

				if (item.resourceType === 'gallery' && item.resourceSubtype === null) {
					return (
						<MainContentMediaContainer
							onRemove={this.onMediaRemove}
							value={item}
							index={index}
							key={'gallery-type-media-key'}
							onContentSelect={this.onMainMediaChange}
							t={t}
							project={project}
							contentType={'gallery'}
						/>
					);
				}

				if (item.resourceType === 'embed' && item.resourceSubtype === 'video') {
					return (
						<MediaVideoEmbed
							key={`ket-${item.resourceType}`}
							index={index}
							t={t}
							onChange={this.onMainMediaChange}
							embedCode={item.data ? item.data.embedCode : ''}
							onRemove={this.onMediaRemove}
							blocks={this.props.blocks}
							contentType={this.props.contentType}
						/>
					);
				}
			});
		}

		return null;
	}

	render() {
		const { mainMediaList, project, mainImage, t, resourceType, contentType } = this.props;
		const imageMediaErrorIndex = mainMediaList && mainMediaList.findIndex((media) => media.resourceType === 'image');
		const selectOptionsWithFilteredFeatures = selectOptions(t).filter((option) => {
			if (option.value === 'embed-video' || option.value === 'media-video') {
				return featuresService.areFeaturesEnabled([FeatureTypes.VIDEO]);
			}

			return true;
		});

		return (
			<>
				<MainImageMedia
					applyWatermark={this.props.applyWatermark}
					contentType={contentType}
					media={mainImage}
					mediaErrorIndex={imageMediaErrorIndex}
					currentProject={project}
					t={t}
					onMainImageMediaUpdate={this.onMainMediaChange}
					displayDescriptionCheckbox={
						contentType === ContentTypes.WIKI_PAGE ||
						contentType === ContentTypes.GALLERY ||
						contentType === ContentTypes.ARTICLE ||
						contentType === ContentTypes.VIDEO
					}
				/>
				{this.displayMainMediaList(mainMediaList, project, t)}

				{contentType !== ContentTypes.GALLERY && (
					<FormGroup>
						<label>{t('choose_main_media')}</label>
						<Select
							options={resourceType.length === 0 ? selectOptionsWithFilteredFeatures : gallerySelectOptions(t)}
							onChange={this.onMainMediaAdd}
							placeholder={t('select')}
							noOptionsMessage={(inputValue) => inputValue && t('no_options')}
							inputId={'choose-main-media-drop-down'}
						/>
					</FormGroup>
				)}
			</>
		);
	}
}
