import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import { FootballTeamMatchStatsJson } from './football-team-match-stats.json';
import FootballTeamMatchStatsWidgetModel from './football-team-match-stats.model';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class FootballMatchStatsBuilder {
	readonly json: FootballTeamMatchStatsJson;

	constructor(teamH2H?: FootballTeamMatchStatsWidgetModel | FootballTeamMatchStatsJson) {
		if (teamH2H && teamH2H instanceof FootballTeamMatchStatsWidgetModel) {
			this.json = teamH2H.toJson();
		} else if (teamH2H) {
			this.json = teamH2H;
		} else {
			this.json = {} as FootballTeamMatchStatsJson;
		}
	}

	withTeamOne(team1: TeamModel): FootballMatchStatsBuilder {
		this.json.team1 = team1;

		return this;
	}

	withTeamTwo(team2: TeamModel): FootballMatchStatsBuilder {
		this.json.team2 = team2;

		return this;
	}

	withTeamOneSeason(team1TournamentSeason: EntitySeasonsSelectModel): FootballMatchStatsBuilder {
		this.json.team1TournamentSeason = team1TournamentSeason;

		return this;
	}

	withTeamTwoSeason(team2TournamentSeason: EntitySeasonsSelectModel): FootballMatchStatsBuilder {
		this.json.team2TournamentSeason = team2TournamentSeason;

		return this;
	}

	withStats(stats: string[]): FootballMatchStatsBuilder {
		this.json.stats = stats;

		return this;
	}

	withMatch(match: MatchModelV2): FootballMatchStatsBuilder {
		this.json.match = match;

		return this;
	}

	withDisplayOdds(displayOdds: boolean): FootballMatchStatsBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballMatchStatsBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballTeamMatchStatsWidgetModel {
		return FootballTeamMatchStatsWidgetModel.fromJson(this.json);
	}
}
