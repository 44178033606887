import { FootballLineupsJson } from './football-lineups.json';
import FootballLineupsModel from './football-lineups.model';
import MatchModelV2 from '../../football-single-event/models/MatchModelV2.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';

export default class FootballLineupsBuilder {
	readonly json: FootballLineupsJson;

	constructor(footballLineups?: FootballLineupsModel | FootballLineupsJson) {
		if (footballLineups && footballLineups instanceof FootballLineupsModel) {
			this.json = footballLineups.toJson();
		} else if (footballLineups) {
			this.json = footballLineups;
		} else {
			this.json = {} as FootballLineupsJson;
		}
	}

	withMatch(match: MatchModelV2): FootballLineupsBuilder {
		this.json.match = match;

		return this;
	}

	withTeams(teams: TeamModel[]): FootballLineupsBuilder {
		this.json.teams = teams;

		return this;
	}

	withHomeTeamColor(homeTeamColor: string): FootballLineupsBuilder {
		this.json.homeTeamColor = homeTeamColor;

		return this;
	}

	withAwayTeamColor(awayTeamColor: string): FootballLineupsBuilder {
		this.json.awayTeamColor = awayTeamColor;

		return this;
	}

	withCoachParameters(coachParameters: Record<string, any>): FootballLineupsBuilder {
		this.json.coachParameters = coachParameters;

		return this;
	}

	withFirstTeamParameters(firstTeamParameters: Record<string, any>): FootballLineupsBuilder {
		this.json.firstTeamParameters = firstTeamParameters;

		return this;
	}

	withSubstitutesParameters(substitutesParameters: Record<string, any>): FootballLineupsBuilder {
		this.json.substitutesParameters = substitutesParameters;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): FootballLineupsBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	build(): FootballLineupsModel {
		return FootballLineupsModel.fromJson(this.json);
	}
}
